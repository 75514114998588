import * as THREE from 'three';
import { OrbitControls } from '../utils/jsm/controls/OrbitControls.js';
import { GLTFLoader } from '../utils/jsm/loaders/GLTFLoader.js';
import { Interaction } from 'three.interaction';
// import { EventDispatcher } from 'three/src/core/EventDispatcher';
const scene_full = 'module2/scene_full.glb';
const obstacles_file = 'module2/collisions-noground.gltf';

const centerFile = (objFile, bbox) => {
  objFile.scale.set( 20, 20, 20 );
  objFile.position.x = -(bbox.min.x + bbox.max.x) / 2;
  objFile.position.y = -(bbox.min.y + bbox.max.y) / 2;
  objFile.position.z = -(bbox.min.z + bbox.max.z) / 2;
}

export default class Utils3D {
  constructor () {
    this.door_z_value = 35;
    this.coords_arr = [];
    this.currentPlayerCoords = new THREE.Vector3();
    this.pickup = [];
    this.persistentHighlight = [];
    this.inventory = [];
    this.distanceToValidateGoal = 4;
    this.distanceToColorObject = 0.5;
    this.question = {};
    this.questionCounter = 0;
    this.previousObj = null;
    this.originalMaterial = null;
    this.newMaterial = null;
    this.shouldColor = true;
    this.shouldHighlight = true;
    this.questionSteps = null;
    this.questionsObjectif = null;
    this.questionsIds = {};
    this.questionId = null;
    this.scene = null;
    this.queryColorableObjects = null;
    this.queryHilightableObjects = null;
    this.goalPickup = false;
  }

  init = ({
    position,
    loader,
    container,
    scene,
    camera,
    renderer,
    controls,
    obstacles,
    setSuperSceneIsLoaded,
    setClickedObject,
    queryColorableObjects,
    queryActivitiesContentModule2
  }) => {
    container = document.getElementById("id3d");
    // Load a Renderer
    renderer = new THREE.WebGLRenderer({ alpha: false });
    renderer.setClearColor( 0xC5C5C3 );
    renderer.setPixelRatio( window.devicePixelRatio );
    renderer.setSize(window.innerWidth, window.innerHeight);
    container.appendChild( renderer.domElement );
    
    scene = new THREE.Scene();
    // Load Camera Perspektive
    camera = new THREE.PerspectiveCamera( 45, window.innerWidth / window.innerHeight, 1, 1000 );
    // camera.position.set( 0, 0.15, 0.6 ); // if scale 1,1,1
    camera.position.set(position.x, position.y, position.z); // if scale 10, 10, 10

    // near defines where the front of the frustum starts, default value = 0.1

    camera.near = 0.05;
    camera.updateProjectionMatrix();

    // Load the Orbitcontroller
    controls = new OrbitControls( camera, renderer.domElement );
    controls.enableKeys = true;
    // ZQSD controls
    controls.keys = { LEFT: 81, UP: 90, RIGHT: 68, BOTTOM: 83 };
    controls.enableZoom = false;
    // controls.enableDamping = true; // to-do we loose collision here, study why
    // controls.dampingFactor = 0.35;
    controls.keyPanSpeed = 2500;
    controls.panSpeed = 130;
    controls.rotateSpeed = 0.5; // mouse sensitivity
    controls.target.set(position.x+0.05, 2, position.z+0.05);

    // Load Light
    const ambientLight = new THREE.AmbientLight( 0xcccccc );
    scene.add( ambientLight );

    const directionalLight = new THREE.DirectionalLight( 0xffffff );
    directionalLight.position.set( 0, 1, 1 ).normalize();
    scene.add( directionalLight );

    // gltf loadng, 4 files
    loader = new GLTFLoader();
    const interaction = new Interaction(renderer, scene, camera);
    
    let bboxScene;
    loader.load(`${process.env.PUBLIC_URL}/${obstacles_file}`, ( objFile ) => {
      // console.log('gltf obstacles', objFile);
      bboxScene = new THREE.Box3().setFromObject(objFile.scene);
      centerFile(objFile.scene, bboxScene);
      objFile.scene.visible = false;
      objFile.scene.name = 'obstacles';
      scene.add( objFile.scene );
      
        // console.log('objFile', objFile);
      objFile.scene.children.map(child => obstacles.push(child));
      loader.load(`${process.env.PUBLIC_URL}/${scene_full}`, (gltf) => {
          // console.log('super_scene', gltf);

          centerFile(gltf.scene, bboxScene);
          gltf.scene.name = 'super_scene';
          // gltf.scene.visible = false;
          scene.add( gltf.scene );
          // console.log('gltf.scene', gltf.scene )
          this.scene = gltf.scene;
          // console.log('gltf.scene.children', gltf.scene.children);
          gltf.scene.children.map(o => {
            o.on('click', (e) => {
              console.log(e.target);
              this.highlight(e.target);
              setClickedObject(e.target);
            });
            o.on('touchstart', (e) => {
              console.log(e.target);
              this.highlight(e.target);
              setClickedObject(e.target);
            });
          });
          setSuperSceneIsLoaded(true);
      });

      this.queryColorableObjects = queryColorableObjects;
      this.queryHilightableObjects = queryActivitiesContentModule2; // should fix strapi bug => no gameplay in questions when query questions node directlu, why ?
      this.getHilightableObjects();

    });

    // for debug : player
    const playerGeo = new THREE.BoxGeometry(0.2, 0.01, 0.2);
    const materialPlayer = new THREE.MeshPhongMaterial({ color: "#1E453E" });

    const player = new THREE.Mesh(playerGeo, materialPlayer);
    player.position.set(position.x, 0.5, position.z);
    player.name = "player";

    scene.add(player);

    return { loader, container, scene, camera, renderer, controls, player, obstacles }
  }

  // push actual coords to coords_arr every time the method is called
  trackMovement (camera) {
    const vector = new THREE.Vector3();
    const coords = camera.getWorldPosition(vector);
    this.coords_arr.push(coords);
    this.currentPlayerCoords = coords;
    // check reachDestination

  }

  // reduce coords_arr to the value of distance between each coord entry
  calcDistanceBetweenVectors () {
    const total_distance = this.coords_arr.reduce(
      (acc, cur, i, arr) => {
        if (arr.length - 1 === i) {
          return acc
        }
        return cur.distanceTo(arr[++i]) + acc
      }, 0)
    return total_distance.toFixed(2);
  }

  detectCollision (player, bboxChild) {
    const bboxPlayer = new THREE.Box3().setFromObject(player);
    const collision = (bboxPlayer.min.x <= bboxChild.max.x && bboxPlayer.max.x >= bboxChild.min.x) &&
     (bboxPlayer.min.z <= bboxChild.max.z && bboxPlayer.max.z >= bboxChild.min.z);

    return collision
  }

  preventCollision (controls)  {
    controls.reset();
  }

  reachDestination = (args) => {

    const { question, goalPut } = args;
    const currentAnswers = question.answers.filter(a => a.gameplay !== 'inventory');
    // const currentAnswers = question.answers;

    const questionId = question._id;

    // console.log('reachDestination');

    if (this.questionId !== questionId) { 
      // console.log('this.questionId', this.questionId);
      // console.log('questionId', questionId);
      this.questionSteps = null;
      this.questionsObjectif = null;
      this.questionsIds = {};
      this.questionId = questionId; // non il est cool, après le click sur feedback, passe bien false
    }

    // Define objective by value of order property
    // for instance : {1: 2, 2: 1} if 2 steps with order 1 and one step with order 2
    if (!this.questionsObjectif) {
      this.questionsObjectif = currentAnswers.reduce((acc, cur) => {
        if (!acc[cur.order]) {
          acc[cur.order] = 1
        } else {
          acc[cur.order] += 1;
        }
        return acc;
      }, {});
    }

    // we initialize counter (per order property)
    // for instance: {1: 0, 2: 0}
    if (!this.questionSteps) {
      this.questionSteps = {...this.questionsObjectif};
      Object.keys(this.questionsObjectif).map(k => this.questionSteps[k] = 0);
    }

    // we compute where player stands in objectives
    currentAnswers.forEach(answer => {
      if (answer.gameplay === 'reach') {
        const { x, y, z } = answer.gameplay_3_d_object.Object3D.coordinates;
        const destination = new THREE.Vector3(x, y, z);
        const distanceToDestination = this.currentPlayerCoords.distanceTo(destination);
        if (distanceToDestination < this.distanceToValidateGoal) {
          if (!this.questionsIds[answer.gameplay_3_d_object.Object3D.file_name]) {
            this.questionsIds[answer.gameplay_3_d_object.Object3D.file_name] = true;
            this.questionSteps[answer.order] += 1;
          }
        }
      } else if ((answer.gameplay === 'put' || answer.gameplay === 'highlight') && goalPut) {
        if (!this.questionsIds[answer.gameplay_3_d_object.Object3D.file_name]) {
          this.questionsIds[answer.gameplay_3_d_object.Object3D.file_name] = true;
          this.questionSteps[answer.order] += 1;
        }
      } else if (answer.gameplay === 'pickup' && this.goalPickup) {
        if (!this.questionsIds[answer.gameplay_3_d_object.Object3D.file_name]) {
          this.questionsIds[answer.gameplay_3_d_object.Object3D.file_name] = true;
          this.questionSteps[answer.order] += 1;
        }
      } 
    })

    // console.log('currentAnswers', currentAnswers);
    // console.log('this.questionSteps', this.questionSteps);
    // console.log('this.questionsObjectif', this.questionsObjectif);
    // console.log('this.questionsIds', this.questionsIds);

    // goooooaaaaaalS
    if (JSON.stringify(this.questionSteps) === JSON.stringify(this.questionsObjectif)) {
      return true;
    } 
  }

  pickUp = (question, addToInventory) => {

    const currentAnswers = question.answers;
    // console.log('this.inventory', this.inventory);
    // console.log('currentAnswers', currentAnswers);
    if (question._id !== this.questionId) {
      if (this.inventory.length) {
        this.inventory.forEach(obj => {
          // console.log(obj.name);
          const res = currentAnswers
            .filter(a => {
              // console.log(a.gameplay_3_d_object.Object3D.file_name)
              return a.gameplay_3_d_object.Object3D.file_name === obj.name
            })[0]
          if (res) {
            const { x, y, z } = res.gameplay_3_d_object
              .Object3D
              .coordinates
            obj.position.set(x,y,z);
            if (obj.getObjectByName(obj.name)) this.scene.add(obj);
          }
        })
      }

      this.inventory = [];
      this.questionSteps = null;
      this.questionsObjectif = null;
      this.questionsIds = {};
      this.goalPickup = false;
      this.questionId = question._id;
    }

    currentAnswers.forEach(answer => {
      const objectName = answer.gameplay_3_d_object.name;
      const fileName = answer.gameplay_3_d_object.Object3D.file_name;
      const obj = this.scene.getObjectByName(fileName);

      if (answer.gameplay === 'pickup' && obj) {

        obj.on('click', e => {
          this.inventory.push(obj);
          this.scene.remove(obj);
          this.goalPickup = true;
          addToInventory({ objectName, fileName, object3D: obj });
        });

        obj.on('touchstart', e => {
          this.inventory.push(obj);
          this.scene.remove(obj);
          this.goalPickup = true;
          addToInventory({ objectName, fileName, object3D: obj });
        });

      }
    });
  }

  pickUpOrder = (question, addToInventory, ) => {

    const currentAnswers = question.answers;
    // console.log('this.inventory', this.inventory);
    // console.log('currentAnswers', currentAnswers);
    if (question._id !== this.questionId) {
        currentAnswers.forEach(a => {
          const cubeName = a.gameplay_3_d_object.Object3D.file_name;
          const cubeSize = parseInt(cubeName.split('_').slice(-1)[0]);
          const cubeColor = cubeName.split('_')[1];
          const { x, y, z } = a.gameplay_3_d_object.Object3D.coordinates;
          const cube = new THREE.BoxGeometry(0.1/cubeSize, 0.1/cubeSize, 0.1/cubeSize);
          const material = new THREE.MeshPhongMaterial({ color: cubeColor === 'vert' ? 'green' : 'orange' });
          const cubeMesh = new THREE.Mesh(cube, material);
          cubeMesh.name = cubeName;
          cubeMesh.position.set(x, 0.01, z);
          this.scene.add(cubeMesh);
        });

        this.inventory = [];
        this.questionSteps = null;
        this.questionsObjectif = null;
        this.questionsIds = {};
        this.goalPickup = false;
        this.questionId = question._id;
    }

    currentAnswers.forEach(answer => {
      const objectName = answer.gameplay_3_d_object.name;
      const fileName = answer.gameplay_3_d_object.Object3D.file_name;
      const obj = this.scene.getObjectByName(fileName);

      if (answer.gameplay === 'pickup_order' && obj) {
        obj.on('click', e => {
          this.inventory.push(obj);
          this.scene.remove(obj);
          addToInventory({ objectName, fileName, object3D: obj });
        });

        obj.on('touchstart', e => {
          this.inventory.push(obj);
          this.scene.remove(obj);
          addToInventory({ objectName, fileName, object3D: obj });
        });
      }
    });
  }

  getColorableObjects = () => {
    const names = [];
    this.queryColorableObjects.questions
      .filter(q => q.choices.length > 0)
      .forEach(q => q.choices.forEach(c => names.push(c.gameplay_3_d_object.Object3D.file_name)));
    const results = Array.from(new Set(names));
    return results;
  }

  toggleColor(callback) {
    const colorableObjectsNames = this.getColorableObjects();
    // console.log('colorableObjectsNames', colorableObjectsNames);
    this.shouldColor = true;

    colorableObjectsNames.map(name => {
      const obj = this.scene.getObjectByName(name);

      obj.on('click', e => {
        const { target } = e;
        // console.log('target', target);
        // first interaction
        if (this.shouldColor) {

          // some child are group, not Mesh. Only Mesh have material
          if (!target.material) {
            target.material = target.children[0].material;
          }
          // console.log('target.material', target.material)
          // console.log('target.material.color', target.material.color)

          if (!this.previousObj) {

            this.originalMaterial = target.material.clone();
            this.newMaterial = target.material.clone();
            this.newMaterial.color.add({'r': 0, 'g': 1, 'b': 0});
            this.newMaterial.needsUpdate = true
            // console.log('this.newMaterial.color', this.newMaterial.color )
          } else {
            this.previousObj.material = this.originalMaterial;
            this.previousObj = null;
          }
          // color target chair
          this.originalMaterial = target.material.clone();
          this.previousObj = target;
          target.material = this.newMaterial;  
          callback(target)
        }
      });

      obj.on('touchstart', e => {
        const { target } = e;
        // console.log('target', target);
        // first interaction
        if (this.shouldColor) {

          // some child are group, not Mesh. Only Mesh have material
          if (!target.material) {
            target.material = target.children[0].material;
          }
          // console.log('target.material', target.material)
          // console.log('target.material.color', target.material.color)

          if (!this.previousObj) {

            this.originalMaterial = target.material.clone();
            this.newMaterial = target.material.clone();
            this.newMaterial.color.add({'r': 0, 'g': 1, 'b': 0});
            this.newMaterial.needsUpdate = true
            // console.log('this.newMaterial.color', this.newMaterial.color )
          } else {
            this.previousObj.material = this.originalMaterial;
            this.previousObj = null;
          }
          // color target chair
          this.originalMaterial = target.material.clone();
          this.previousObj = target;
          target.material = this.newMaterial;  
          callback(target)
        }
      });
    })
  }

  stopColor = () => {
    this.shouldColor = false;
  }

  getHilightableObjects = () => {
    const names = [];
    this.queryHilightableObjects
      .modules[0]
      .activities
      .forEach(ac => ac.images
        .forEach(i => i.questions
          .forEach(q => q.answers
            .filter(an => an.gameplay === 'put')
            .forEach(an => names.push(an.gameplay_3_d_object.Object3D.file_name))
            )))
    const results = Array.from(new Set(names));
    return results;
  }

  highlight = (target) => {
    this.shouldHighlight = true;

    if (this.shouldHighlight) {
      const hilightableObjects = this.getHilightableObjects();
      // console.log('hilightableObjects', hilightableObjects);
      if (target.material && hilightableObjects.includes(target.name)) {
        if (!this.previousObj) {
          this.originalMaterial = target.material.clone();
          this.newMaterial = target.material.clone();
          this.newMaterial.color.add({ 'r': 0, 'g': 1, 'b': 0 });
        } else {
          this.previousObj.material = this.originalMaterial;
          this.previousObj = null;
        }
        // color target chair
        this.originalMaterial = target.material.clone();
        this.previousObj = target;
        target.material = this.newMaterial;
      }
    }
  }

  stopHighlight = () => {
    this.shouldHighlight = false;
  }

  presetInventory = (question, addInventory) => {
    if (
      this.question !== question._id
    ) {
      this.question = question._id;

      question.answers.forEach(a => {
        const objectName = a.gameplay_3_d_object.name;
        const fileName = a.gameplay_3_d_object.Object3D.file_name;
        const cubeSize = parseInt(fileName.split('_').slice(-1)[0]);
        const cubeColor = fileName.split('_')[1];
        const { x, y, z } = a.gameplay_3_d_object.Object3D.coordinates;
        const cube = new THREE.BoxGeometry(0.1/cubeSize, 0.1/cubeSize, 0.1/cubeSize);
        const material = new THREE.MeshPhongMaterial({ color: cubeColor === 'vert' ? 'green' : 'orange' });
        const cubeMesh = new THREE.Mesh(cube, material);
        cubeMesh.name = fileName;
        cubeMesh.position.set(x, 0.01, z);
        addInventory({ objectName, fileName, object3D: cubeMesh });
      });

    }
  }

  presetInventoryAndRemoveObjectFromScene = (question, addInventory) => {
    const objectName = question.answers[0].gameplay_3_d_object.name;
    const fileName = question.answers[0].gameplay_3_d_object.Object3D.file_name;
    if (
      fileName &&
      this.question !== question._id
    ) {
      this.question = question._id;
      const object3D = this.scene.children.find(o => o.name === fileName);
      object3D.visible = false;
      // this.scene.remove(object3D);
      // console.log('SHOULD SET CAHIERS, object3D', object3D)
      addInventory({ objectName, fileName, object3D });
    }
  }
  
}
