import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Switch, Redirect, useLocation } from "react-router-dom";
import { ApolloProvider } from '@apollo/react-hooks';
import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { HttpLink } from 'apollo-link-http';
import { onError } from 'apollo-link-error';
import { ApolloLink, Observable } from 'apollo-link';
import { IntrospectionFragmentMatcher } from 'apollo-cache-inmemory';
import * as Sentry from '@sentry/browser';

import introspectionQueryResultData from './fragmentTypes.json';

import useAuth from './hooks/useAuth';

import * as ROUTES from './constants/routes';

import StoreProvider, { StoreContext } from "./Store";
import Splash from './pages/Splash';
import Activities from "./pages/Activities";

import Layout from "./components/Layout";
import Loading from './components/Loading';

import Error403 from './pages/Error403';
import Error500Strapi from './pages/Error500Strapi';
import Error403Strapi from './pages/Error403Strapi';
import Logout from './pages/Logout';

import Home from './pages/Home.js';

import 'antd/dist/antd.css';
import './scss/App.scss';
import ComingSoon from "./pages/ComingSoon.js";


const App = () => {

  // const location = useLocation();
  // connection to strapi cms
  const { strapiAuth } = useAuth();

  const [error403Strapi, dispatchError403Strapi] = useState(false);
  const [loadingAuthStrapi, dispatchLoadingAuthStrapi] = useState(true);
  const [jwt, setJwt] = useState();

  useEffect(() => {
    strapiAuth()
      .then((token) => {
        console.log('token strapi is : ', token);
        setJwt(token);
        dispatchLoadingAuthStrapi(false);
      })
      .catch((error) => {
        console.log(error);
        Sentry.captureException(error);
        dispatchError403Strapi(true);
        dispatchLoadingAuthStrapi(false);
      })
  }, []);

  // strapi graphql configuration
  const request = (operation) => {
    operation.setContext({
      headers: {
        authorization: jwt ? `Bearer ${jwt}` : ''
      }
    })
  };

  const requestLink = new ApolloLink((operation, forward) =>
    new Observable(observer => {
      let handle;
      Promise.resolve(operation)
        .then(oper => request(oper))
        .then(() => {
          handle = forward(operation).subscribe({
            next: observer.next.bind(observer),
            error: observer.error.bind(observer),
            complete: observer.complete.bind(observer),
          });
        })
        .catch(observer.error.bind(observer));

      return () => {
        if (handle) handle.unsubscribe();
      };
    })
  );

  // strapi dynamic fields are unions types : https://strapi.io/documentation/3.0.0-beta.x/plugins/graphql.html#fetch-dynamic-zone-data
  // apollo-client needs a fragment matcher to be able to query fragment unions in graphql : https://www.apollographql.com/docs/react/data/fragments/#fragments-on-unions-and-interfaces

  const fragmentMatcher = new IntrospectionFragmentMatcher({
    introspectionQueryResultData
  });

  const client = new ApolloClient({
    link: ApolloLink.from([
      onError(({ graphQLErrors, networkError }) => {
        if (graphQLErrors)
          graphQLErrors.forEach(({ message, locations, path }) => {
            console.log(
              `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
            );
            Sentry.captureException(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`);
          });
        if (networkError) {
          console.log(`[Network error]: ${networkError}`);
          Sentry.captureException(`[Network error]: ${networkError}`);
        }
      }),
      requestLink,
      new HttpLink({
        uri: process.env.REACT_APP_STRAPI_GRAPHQL_ENDPOINT,
        credentials: 'same-origin'
      })
    ]),
    cache: new InMemoryCache({ fragmentMatcher })
  });

  if (process.env.NODE_ENV === 'production') {
    console.log = () => {}
  }

  // console.log = () => {}


  return console.log('process.env.PUBLIC_URL', process.env.PUBLIC_URL) || (
    <ApolloProvider client={client}>
      <StoreProvider>
        <StoreContext.Consumer>
          {({
              user: {
                user
              },
              error: {
                error
              },
              loading: {
                loading
              }
            }) => {
            if (error && error.auth) {
              return <Error403 />
            } else if (error403Strapi) {
              return <Error403Strapi />
            } else if (error && error.strapi) {
                return <Loading />
            } else if (!Object.values(loading).every(item => !item) || loadingAuthStrapi) {
              return <Loading />
            } else {
              return (
                <Router>
                  {!user.id && <Redirect to={`/${ROUTES.EAN13}/${ROUTES.SPLASH}`} />}
                  <Layout> 
                    <Switch>
                      <Route exact path={`/${ROUTES.EAN13}/${ROUTES.SPLASH}`} render={() => <Splash />} />
                      <Route exact path={`/${ROUTES.EAN13}/${ROUTES.LOGOUT}`} render={() => <Logout />} />
                      <Route exact path={`/${ROUTES.EAN13}/${ROUTES.HOME}`} render={() => <Home />} />
                      <Route exact path={`/${ROUTES.EAN13}/${ROUTES.HOME_RESSOURCES}`} render={() => <Home />} />
                      <Route exact path={`/${ROUTES.EAN13}/${ROUTES.COMING_SOON}`} render={() => <ComingSoon />} />
                      <Route path={`/${ROUTES.EAN13}/:moduleParam`} render={() => <Activities />} />
                    </Switch>
                  </Layout>
                </Router>
              )
            }
          }}
        </StoreContext.Consumer>
      </StoreProvider>
    </ApolloProvider>
  );
}

export default App;
