import gql from 'graphql-tag'; 

export const GET_WIP_MODULES = gql`
  query {
    modules(start: 3, sort:"module_id:asc")  {
      id
      module_id
      name
      description
    }
  }
`