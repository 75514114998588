export default (state, action) => {
    console.log("reducer USER");
    console.log("Action", action.type);
    switch (action.type) {
      case 'DEMO_AUTH':
        console.log({
          id: `${action.payload}-DEMO`,
          roles: [...action.payload]
        })
        return {
          id: `${action.payload}-DEMO`,
          roles: [...action.payload]
        }
      case 'RESET':
        return {
          roles: [],
        }
      default:
        return state;
    }
}