import React, { useState, useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Breadcrumb } from 'antd';

import { StoreContext } from '../Store';

import useId from '../hooks/useId';
import useActivity from '../hooks/content/useActivity';
import useExercice from '../hooks/content/useExercice';
import useQuestions from '../hooks/content/useQuestions';

import Gameplay3DContainer from '../containers/Gameplay3DContainer'
import GameplayContainer from '../containers/GameplayContainer';
import { Chatbot } from '../containers/Chatbot';

import Feedback from '../components/Feedback';
import ProgressBar from '../components/ProgressBar';
import InstructionMedia from '../components/InstructionMedia';
import InstructionText from '../components/InstructionText';

import "../scss/Exercice.scss"

export default () => {

    const {
        ia:{
            dispatchFeedback
        },
        history: {
            answers,
        },
        gameloop: {
            dispatchGameloop
        },
        chatbot:{
            showChatbot
        },
        questions:{
            questions,
            setQuestions
        }
    } = useContext(StoreContext);

    const [moduleId] = useId(1, 'moduleParam');
    const [activityId] = useId(1, 'activityParam');
    const [imageId] = useId(1, 'exerciceParam');

    const loadThree = (moduleId === 2);

    const image = useExercice({});
    const activity = useActivity({});
    const { getQuestions } = useQuestions();
    const [showMedia, setShowMedia] = useState(false);

    useEffect(() => {
        
        if (questions.length <= 0) {
            getQuestions(activity);
        }

        if (questions.length > 0 && questions[0].__typename === "ComponentGameplayQcm") {
            setShowMedia(true);
        } else {
            setShowMedia(false);
        }
    }, [answers, image, questions]);
    
    useEffect(() => {
        getQuestions(activity);
        return () =>{
            setQuestions([])
            dispatchFeedback({ type: "RESET_FEEDBACK" })
        }
    },[activity])

    useEffect(() => {
        dispatchGameloop({
            type: 'SET_EXERCICE',
            payload: {
                moduleId,
                activityId,
                imageId,
                questionId: 1
            }
        });
    }, [moduleId, activityId, imageId])

    const location = useLocation();

    const breadcrumbItems = ['accueil', ...location.pathname.split('/').slice(2)];

    const gameplay = <>
        <div id="exercice-container" className={loadThree ? "module-3d" : null}>
            <div className="col left">
                {loadThree ?  <Gameplay3DContainer /> : (showMedia ? <InstructionMedia /> : <GameplayContainer />)}
            </div>
            <div className="col right">
                {questions.length > 1 && <ProgressBar />}
                <div id="instructions-container">
                    <InstructionText />
                    { showChatbot === null || showChatbot === true ? <Chatbot /> : null }
                </div>
                { showMedia ? <GameplayContainer /> : null }
                <Feedback />
            </div>
        </div>
    </>

    const centered = (loadThree) ? "centered-canvas" : "centered";

    return <div id="activity-container" className={centered}>
        <Breadcrumb separator=">">
            {breadcrumbItems.map( item => ( <Breadcrumb.Item key={item}>{ item.replace('-', ' ').replace(/^\w/, c => c.toUpperCase()) === "Activite 0" ? "Activite introductive" : item.replace('-', ' ').replace(/^\w/, c => c.toUpperCase())}</Breadcrumb.Item>) )}
        </Breadcrumb>
        {gameplay}
    </div>
}



