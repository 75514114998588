import { useEffect, useState, useContext } from 'react';

import useExercices from './useExercices';
import { StoreContext } from '../../Store';
import useId from '../useId';
//import useLabel from '../useLabel';

export default (init) => {

    const [imageId] = useId(1, 'exerciceParam');
    const images = useExercices([]);
    const [image, setImage] = useState(init);

    useEffect(() => {
        // console.log('in useExercice, imageId', imageId);

        if (images && images.length) {
            const img = images
                .find(img => img.image_id === imageId);
                setImage(img);
        }
     
    }, [images, imageId]);
    return image;
}