import React, { useContext } from 'react';
import { StoreContext } from '../Store';

import Gameplay3D from '../components/gameplay/Gameplay3D';

import useModule from '../hooks/content/useModule';
import useActivity from '../hooks/content/useActivity';
import useExercice from '../hooks/content/useExercice';
import useQuestion from '../hooks/content/useQuestion';

const Gameplay3DContainer = () => {
  const {
      history: {
        dispatchAnswers
      },
      gameloop: {
        dispatchGameloop
      },
      ia: {
        dispatchFeedback
      },
      modal: {
        setShowModal
      }
    } = useContext(StoreContext);

    const modul = useModule({});
    const activity = useActivity({});
    const image = useExercice({});
    const question = useQuestion({});
    
    const submitAnswer = (isCorrect) => {
      dispatchAnswers({
        type: "ADD_ANSWER",
        payload: {
          module_id: modul._id,
          activity_id: activity._id,
          image_id: image._id,
          question_id: question._id,
          answers_id: '',
          isCorrect: isCorrect
        }
      });
      if (isCorrect) {
        dispatchFeedback({ type: "SUCCESS_QUESTION", payload: { question } });
      } else {
        dispatchFeedback({ type: "ERROR_QUESTION", payload: { question } });
      }
    }

 return (
    <Gameplay3D
      submitAnswer={submitAnswer}
    />
  )
}

export default Gameplay3DContainer;
