import React, { useContext } from 'react';
import { Affix } from 'antd';

import { Logo, MainNav } from './Navigation';

import Footer from './Footer';
import "../scss/Layout.scss"
// import { StoreContext } from '../containers/Store';

import '../scss/Layout.scss';

const LayoutContainer = ({ children }) => {

  // const { user: { user: authUser } } = useContext(StoreContext);

  return (
    <div id="layout">
      <Affix>
        <div id="header">
          <Logo />
          <MainNav />
        </div>
      </Affix>

      <div id="content">
        <div className="">
          {children}
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default LayoutContainer;
