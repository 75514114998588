import React, { useRef } from 'react'
import useModule from '../hooks/content/useModule';

import '../scss/Audio.scss'

function AudioElement({ voice, children }) {

    const modul = useModule({});
    const path = "/module" + modul.module_id + "/voices/" + voice + ".mp3";
    const audioRef = useRef(null)

    const muteAudio = (elem) => {
        elem.muted = true;
        elem.pause();
    }

    const playAudio = (e) => {
        e.preventDefault()
        document.querySelectorAll("audio").forEach( elem => muteAudio(elem) );
        audioRef.current.muted = false;
        audioRef.current.currentTime = 0;
        audioRef.current.play();
    }

    return (
        <div className="audio-container" onClick={playAudio}>
            {children}
            <button className="btn"><span class="material-icons">play_arrow</span></button>
            <audio ref={audioRef} style={{ display: 'none' }} src={path}></audio>
        </div>
    )
}

export default AudioElement
