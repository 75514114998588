import React from 'react';
import { Spin } from 'antd';


const Loading = () => {
  return (
    <div style={{ width: '80%', margin: 'auto' }}>
      <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', marginTop: '30%' }}>
          <Spin tip="Loading..."/>
      </div>
    </div>
  );
};

export default Loading;