import React, { useEffect, useContext, useState } from 'react';
import * as THREE from 'three';

import { StoreContext } from '../../Store';

import InventoryLine from '../InventoryLine';

import useAnimationFrame from '../../hooks/useAnimationFrame';
import useInterval from '../../hooks/useInterval';
import useExercice from '../../hooks/content/useExercice';
import useQuestion from '../../hooks/content/useQuestion';
import Utils3DClass from '../../utils/Utils3D';

import "../../scss/Gameplay3D.scss"

const mouse = new THREE.Vector2();

const Utils3D = new Utils3DClass();

// Init raycaster and color hovered objects
const raycaster = new THREE.Raycaster();


const Gameplay3D = ({ submitAnswer }) => {

  const {
    content: {
      queryColorableObjects,
      queryActivitiesContentModule2
    },
  } = useContext(StoreContext);

  const exercice = useExercice({});
  const question = useQuestion({});

  // const questionId = useId(1, 'questionParam');
  const [playerPosition, setPlayerPosition] = useState({ x: 85.5, y: 2, z: 64.9 });
  const [args, setArgs] = useState(null);

  const render = ({ scene, camera, renderer, controls, player, obstacles }) => {
    if (player) {
      const vector = new THREE.Vector3();
      const coords = camera.getWorldPosition(vector);
      
      player.position.set(coords.x, 0.5, coords.z);
      // console.log('coords', coords);

      obstacles.forEach(child => {
        const bboxChild = new THREE.Box3().setFromObject(child);
        const collision = Utils3D.detectCollision(player, bboxChild);
        if (collision) {
          console.log("BOOM");
          Utils3D.preventCollision(controls);
        }
      });
      // update the picking ray with the camera and mouse position
      raycaster.setFromCamera(mouse, camera);
    }
    controls.saveState();
    renderer.render(scene, camera);
    return { scene, camera, renderer, controls, player, obstacles };
  }

  // init when component is loaded
  const [isMouseMoving, setIsMouseMoving] = useState(false);
  const [isMouseDown, setIsMouseDown] = useState(false);
  const [superSceneIsLoaded, setSuperSceneIsLoaded] = useState(false);
  const [clickedObject, setClickedObject] = useState(false);

  const [timer, setTimer] = useState(0);
  useInterval(() => {
    // console.log(timer);
    setTimer(timer + 1);
  }, 1000);

  useEffect(() => {
    if (timer > 120 && !args.goal && !args.goalPut && !args.goalPutInPlaces) {
      submitAnswer(false);
    }
  }, [timer])


  useEffect(() => {
    let argsInit = Utils3D.init({
      position: playerPosition,
      loader: null,
      container: null,
      scene: null,
      camera: null,
      renderer: null,
      controls: null,
      player: null,
      obstacles: [],
      setSuperSceneIsLoaded,
      setClickedObject,
      queryColorableObjects,
      queryActivitiesContentModule2
    });
    setArgs({...argsInit, selectedIndices: [], selectedObjects: []});
    window.addEventListener('mousedown', () => setIsMouseDown(true));
    window.addEventListener('mousemove', (event) => {
      // console.log('argsInit', argsInit);
      const mouse = new THREE.Vector2();

      // calculate mouse position in normalized device coordinates
      // (-1 to +1) for both components
      mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
      mouse.y = - (event.clientY / window.innerHeight) * 2 + 1;
      if (isMouseDown) {
          setIsMouseMoving(true);
      }
    });
    window.addEventListener('mouseup', (e) => {
      setIsMouseDown(false);
      setIsMouseMoving(false);
    });
  }, []);

  // for each question, reset player position
  useEffect(() => {  
    // console.log('NEW EXERICE', exercice); 
    // console.log('NEW QUESTION', question);
    
    if (args) {
      const { camera, controls, player, scene } = args;
      camera.position.set(playerPosition.x, playerPosition.y, playerPosition.z);
      controls.target.set(playerPosition.x + 0.05, 2, playerPosition.z + 0.05);
      camera.updateProjectionMatrix();
      player.position.set(playerPosition.x, playerPosition.y-1.5, playerPosition.z);
      // console.log('setArgs {...args, question }', {...args, question });
      setInventory([]);
      setTimer(0);
      currentStack.forEach(cube => {
        superScene.remove(cube);
      });
      setCurrentStack([]);
      setArgs({...args, question, goal: false, goalPut: false, goalPutInPlaces: 0 });
    }
  }, [question]);

  const getGameplays = (args) => {
    if (args && args.question) {
      let gameplays = {};
      if (args.question.answers && args.question.answers.length) {
        gameplays = args.question.answers.reduce((acc, cur) => {
          acc[cur.gameplay] = true
          return acc
        }, {})
      } else if (args.question.choices && args.question.choices.length) {
        gameplays['color'] = true;
      }
      return gameplays
    } 
  }

  // gameloop
  useAnimationFrame(() => {

    if (args && args.question &&
      (
        args.question.answers && args.question.answers.length ||
        args.question.choices && args.question.choices.length
      )
    ) {
      const gameplays = getGameplays(args);
      // detect when player reach destination
      if (gameplays.reach || gameplays.highlight) {
        Utils3D.trackMovement(args.camera);
        // add intermediary goals as a bool in reachDestination
        const goal = Utils3D.reachDestination(args);  
        if (goal && !args.goal) {
          submitAnswer(goal);
          setArgs({...args, goal: true });
        }    
      }
      render(args);
    }
  }, args);


  // get scene
  const [superScene, setSuperScene] = useState(null);
  useEffect(() => {
    // console.log('superSceneIsLoaded', superSceneIsLoaded)
    if (superSceneIsLoaded && args && args.scene) {
      const superScene = args.scene.children.find(scene => scene.name === 'super_scene');
      setSuperScene(superScene);
    }
  }, [args, superSceneIsLoaded]);

  useEffect(() => {
    if (args) console.log('args.question', args.question)
  }, [args])

  const [inventory, setInventory] = useState([]);
  // detect when player interact with scene objects
  useEffect(() => {
    if (args) {
      const { question } = args;
      if (question && superScene) {
        const gameplays = getGameplays(args);
        console.log('gameplays', gameplays);
        if (gameplays.color && superScene) {
          // console.log(superScene)
          Utils3D.toggleColor((target) => {
            submitAnswer(target);
            setArgs({...args, goal: true });
          });
        } else if (superScene && gameplays.pickup) {
          console.log('PICKUP')
          Utils3D.pickUp(question, (inventoryObj) => {
            inventory.push(inventoryObj);
            setInventory([...inventory]);
          })
        } else if (superScene && gameplays.pickup_order) {
          console.log('PICKUP_INORDER')
          Utils3D.pickUpOrder(question, (inventoryObj) => {
            inventory.push(inventoryObj);
            setInventory([...inventory]);
          });
        } else if (superScene && gameplays.inventory) { // set object in inventory when question say so
          Utils3D.presetInventoryAndRemoveObjectFromScene(question, ({ objectName, fileName, object3D }) => {
            if (inventory.length === 0) {
              inventory.push({ objectName, fileName, object3D });
              setInventory([...inventory]);
            }
          });
        } else if (superScene && gameplays.put_inplace) { // set object in inventory when question say so
          Utils3D.presetInventory(question, ({ objectName, fileName, object3D }) => {
            inventory.push({ objectName, fileName, object3D });
            setInventory([...inventory]);
          });
          const expectedAnswer = question.answers.map(a => a.gameplay_3_d_object.Object3D.file_name);
          if (inventory.length === 0) {
            if (args.goalPutInPlaces === expectedAnswer.length) {
              submitAnswer(true);
            } else {
              submitAnswer(false);
            }
          }
        }
        if (!gameplays.color) {
          Utils3D.stopColor();
        }
        if (gameplays.firstGameplay !== 'put') {
          // Utils3D.stopHighlight();
        }
      }
    }
  }, [args, superScene]);

  useEffect(() => {
    if (args) {
      const { question } = args;
      const gameplays = getGameplays(args);
      if (gameplays && gameplays.pickup_order) {
        const expectedAnswer = question.answers.map(a => a.gameplay_3_d_object.Object3D.file_name);
        const givenAnswer = inventory.map(({ object3D }) => object3D.name);
        //to-do goal ?
        if (expectedAnswer.length && expectedAnswer.length === givenAnswer.length) {
          console.log('expectedAnswer', expectedAnswer);
          console.log('givenAnswer', givenAnswer);
          if (JSON.stringify(expectedAnswer) === JSON.stringify(givenAnswer)) {
            console.log('BRAVO')
            submitAnswer(true);
          } else {
            console.log('CRAMPE')
            submitAnswer(false);
          }
        }
      }
    }
  }, [args, inventory])

  const selectObjectFromInventory = ({ fileName }) => {
    const { selectedIndices, selectedObjects, question } = args;
    const gameplays = getGameplays(args);
    // selectIndex
    const selectedIndex = inventory.findIndex(obj => obj.fileName.toLowerCase() === fileName.toLowerCase());
    console.log('selectedIndex', selectedIndex);

    if (selectedIndex !== -1) {
      if (selectedIndices.includes(selectedIndex)) {
        selectedIndices.splice(selectedIndices.findIndex(i => i === selectedIndex), 1)
      } else {
        selectedIndices.push(selectedIndex);
      }
      setArgs({...args, selectedIndices });
    }

    // selectedObject
    const selectedObject = inventory.find(obj => obj.fileName.toLowerCase() === fileName.toLowerCase()).object3D;
    if (selectedObject) {
      if (selectedObjects.includes(selectedObject)) {
        selectedObjects.splice(selectedObjects.findIndex(o => JSON.stringify(o) === JSON.stringify(selectedObject)), 1);
      } else {
        selectedObjects.push(selectedObject);
      }
      setArgs({...args, selectedObjects });
    }

    if (gameplays.highlight || gameplays.put) {
      putObjectInSceneWithClickTarget(selectedIndices, selectedObjects);
    } else if (gameplays.put_inplace) {
      putObjectInSceneAtPlayerPosition(selectedIndices, selectedObjects);
    }
  }

  const putObjectInSceneWithClickTarget = (selectedIndices, selectedObjects) => {
    // to-do
    const putTarget = args.question.answers.find(a => a.gameplay === 'put' || a.gameplay === 'highlight');
    console.log('putTarget', putTarget);
    if (
      selectedIndices.length &&
      selectedObjects.length &&
      putTarget &&
      putTarget.gameplay_3_d_object.Object3D.file_name === clickedObject.name
    ) {
      const { x, y, z } = clickedObject.position;
      selectedIndices.forEach(i => {
        inventory.splice(i, 1);
        setInventory([...inventory]);
      });
      selectedObjects.forEach(o => {
        // set position to player's position, approximately, pb with scale since import / export to and from blender...
        o.position.set(x, y, z);
        superScene.add(o);
      });
      setArgs({...args, selectedIndices: [], selectedObjects: [], goalPut: true });
    }
  }
  
  const [currentStack, setCurrentStack] = useState([]);
  const putObjectInSceneAtPlayerPosition = (selectedIndices, selectedObjects) => {
    // to-do
    const destinationPlaces = args.question.answers.filter(a => a.gameplay === 'put_inplace');
    if (
      selectedIndices.length &&
      selectedObjects.length
    ) {
      selectedIndices.forEach(i => {        
        inventory.splice(i, 1);
        setInventory([...inventory]);
      });

      const playPositionDescaled = {
        x: args.player.position.x / 20 +0.31,
        y: args.player.position.y / 20,
        z: args.player.position.z / 20 + 0.14
      };

      let dx, dy, dz;
      selectedObjects.forEach(o => {
        // console.log('destinationPlaces.find(dest => dest.gameplay_3_d_object.Object3D.file_name === o.name)', destinationPlaces.find(dest => dest.gameplay_3_d_object.Object3D.file_name === o.name))
        const {x, y, z} = destinationPlaces
            .find(dest => dest.gameplay_3_d_object.Object3D.file_name === o.name)
            .gameplay_3_d_object
            .Object3D
            .coordinates
        dx = x;
        dy = y;
        dz = z;
        o.position.set(playPositionDescaled.x, playPositionDescaled.y, playPositionDescaled.z);
        superScene.add(o);
        currentStack.push(o);
        setCurrentStack([...currentStack]);
      });

      const origin = new THREE.Vector3(playPositionDescaled.x, playPositionDescaled.y, playPositionDescaled.z); 
      const destination = new THREE.Vector3(dx, dy, dz);

      // console.log('origin', origin);
      // console.log('destination', destination);
      
      console.log('args.player.position.distanceTo(destination)', origin.distanceTo(destination));
      const goalPutInPlaces = (args.goalPutInPlaces + 1) * (origin.distanceTo(destination) < 0.2);
      console.log('goalPutInPlaces', goalPutInPlaces)
      console.log('args.goalPutInPlaces', args.goalPutInPlaces)
      setArgs({...args, selectedIndices: [], selectedObjects: [], goalPutInPlaces });

    }

  }

  return (
    <div id="gameplay3D-container">
      <div id="inventory" className="card">
        <h3>Trousseau</h3>
        {inventory.map(({ objectName, fileName }, i) => (
          <InventoryLine
            key={i}
            objectName={objectName}
            fileName={fileName}
            onClick={selectObjectFromInventory}
          />
        )
        )}
      </div>
      <div id="id3d"></div>
    </div>
  )
}

export default Gameplay3D;