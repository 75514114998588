import React, { useContext, useEffect } from 'react';
import { Redirect, useRouteMatch, Link, Route, Switch } from 'react-router-dom';
import * as ROUTES from '../constants/routes';
import * as ROLES from '../constants/roles';

import { StoreContext } from '../Store';

import useId from '../hooks/useId';
import useLabel from '../hooks/useLabel';
import useModule from '../hooks/content/useModule';
import useActivities from '../hooks/content/useActivities';

import Exercice from './Exercice';
import '../scss/Activities.scss';

const Activities = () => {

    const {
        user: {
            user
        },
        gameloop: {
            dispatchGameloop
        }
    } = useContext(StoreContext);

    let { url, path } = useRouteMatch();

    const modul = useModule([]);

    const activities = useActivities([]);

    const [moduleId] = useId(1, 'moduleParam');

    useEffect(() => {
        dispatchGameloop({ type: 'SET_MODULE', payload: { moduleId }});
    }, [moduleId])

    //const label_id = useLabel('chapter_id', 'exercice_id');
    //const label = useLabel('chapters', 'exercices');

    return (
        <Switch>
            {/* DEMO STUDENT */}
            { user && user.roles && user.roles.includes(ROLES.STUDENT) && <Route exact path={`/${ROUTES.EAN13}/:moduleParam`} render={() => <Redirect to={`${url}/activite-0/question-1`} />} />}
            
             {/* CATALOG (TEACHER MOD) */}
            {user && user.roles && user.roles.includes(ROLES.TEACHER) && <Route exact path={`${path}`} render={() => (
                <div className="activities-container centered">
                    <div className="title-container">
                        <h1> Module {modul.module_id} : {modul.name} </h1>
                    </div>
                    <div className="bottom-container">
                        <div className="items-container">
                            {activities.map(act => (
                                act.images && act.images.length > 0 && <div className="activity-item card content" key={act._id}>
                                    <h2>{ act.activity_id === 0 ? "Introductive activity": "Activity " + act.activity_id }: {act.name} </h2>
                                    <ul className="chapter-items">
                                        {act.images.map(img => (
                                            <li key={img._id} className="card">
                                                <Link to={`${url}/activity-${act.activity_id}/image-${img.image_id}/question-1`}>
                                                    <img src={img.media ? `/module${modul.module_id}/images/${img.media}.jpg` : `/module${modul.module_id}/images/${img.questions[0].media}.jpg` } />
                                                </Link>
                                            </li>
                                        )
                                        )}
                                    </ul>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            )} />}

            { user && user.roles && user.roles.includes(ROLES.STUDENT) ? <Route path={ `${path}/:activityParam/:questionParam`} render={ () => <Exercice /> } /> : <Route path={ `${path}/:activityParam/:exerciceParam/:questionParam` } render={() => <Exercice /> } /> }

        </Switch>
    )
}

export default Activities;