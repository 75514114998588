import React, { useState, createRef, useEffect, useContext } from 'react'

import useModule from '../../hooks/content/useModule';
import { StoreContext } from '../../Store';

import '../../scss/Diorama.scss'

function Diorama({data, submit}) {

    const {
        chatbot: {
            showChatbot,
            setShowChatbot
        }
    } = useContext(StoreContext);

    const { carousel, arrows, image } = data;

    const [timeouts, setTimeouts] = useState([]);
    const [slide, setSlide] = useState();
    const [show, setShow] = useState(false);
    const [arrowId, setArrowId] = useState();
    const [displayAnswer, setDisplayAnswer] = useState(null)

    const modul = useModule({})

    const button = createRef();

    const StartCarousel = () =>{
        button.current.className = "hide"
        slideAnimation(0, carousel[0].timer)
    }

    const slideAnimation = (i, timer) =>{

            setSlide(carousel[i].image)

            let timeout = setTimeout( () =>{
                let array = timeouts;
                array.push(timeout)
                setTimeouts(array)
                if( carousel[i + 1] ){
                    slideAnimation( i + 1, carousel[i + 1].timer )
                }else{
                    setSlide()
                    setShow( true )
                    timeouts.forEach( el => clearTimeout(el) )
                    setShowChatbot(true)
                }

            }, timer * 1000 )
    }

    const onClickHandler = (isCorrect, arrow_id) => {

        if(!displayAnswer){
            setArrowId(arrow_id);
            submit(isCorrect, arrow_id)
            setDisplayAnswer(true)
        }
        
    }

    useEffect(()=>{
        setShowChatbot(false)
        return () =>{
            setDisplayAnswer(null)
        }
    },[])

    return (
        <div className="diorama-container">
            <div className={ !show ? "carousel show" : "carousel hide" }>
                <button className="btn" ref={button} onClick={StartCarousel}>Begin</button>
                <div className="slide">
                    {
                        slide && <img src={"/module" + modul.module_id + "/images/" + slide + ".jpg"} />
                    }
                </div>
            </div>
            <div className = { show ? "content show" : "content hide" } >
                <div className="arrow-container">
                    <div className="svg">
                        <svg width="640" height="480" viewBox="0 0 169.33333 127">
                                <g>
                                    {
                                        arrows && arrows.map( arrow => <path key={arrow._id} className={displayAnswer && arrow.isTrue ? "arrow show" : "arrow"} onClick={ () => onClickHandler(arrow.isTrue, arrow._id) }  d={arrow && arrow.svg} /> )
                                    }
                                </g>
                        </svg>
                        <img src={ !displayAnswer ? "/module" + modul.module_id + "/images/" + image + ".jpg" : "/module" + modul.module_id + "/images/" + carousel[0].image + ".jpg"} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Diorama
