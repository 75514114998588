import React, { useContext, useEffect, useState } from 'react';
import { Link, Redirect } from 'react-router-dom';

import { StoreContext } from '../Store';

import StudentImage from '../assets/images/Gabriel.jpg';
import TeacherImage from '../assets/images/Leonie.JPG';
import fonctionalitesImg from '../assets/images/fonctionnalites_des_modules.jpg';

import * as ROLES from '../constants/roles';
import * as ROUTES from '../constants/routes';
// import Loading from '../components/Loading';

import '../scss/Splash.scss';


const Splash = () => {
  const {
    user: {
      user: authUser,
      dispatchUser
    },
    // loading: {
    //   loading
    // },
    // error: {
    //   error
    // }
  } = useContext(StoreContext);

  const [redirectRoute, setRedirectRoute] = useState();

  useEffect(() => {
    console.log('SPLASH IS MOUNTED');
  }, []);

  // when auth

  // useEffect(() => {
  //   let redirectRoute;
  //   console.log(loading);
  //   if (Object.values(loading).every(l => l === false)) {
  //     if (authUser && authUser.roles.includes(ROLES.TEACHER)) {
  //       // redirectRoute = `/${ean13}/${modul}/${ROUTES.DASHBOARD}`;
  //     } else {
  //         redirectRoute = `${ean13}/${modul}/${ROUTES.GAME}`;
  //         setRedirectRoute(redirectRoute);
  //       } 
  //   }
  // }, [authUser, loading])

  // if (!redirectRoute && !error) {
  //   return (
  //     <Loading />
  //   )
  // } else {    
  //   console.log('redirectRoute', redirectRoute);
  //   return <Redirect to={redirectRoute}/>;
  // }

  // to-do onclick dispatchUser auth : roles.student or teacher
  if (!authUser.id) {
    return (
      <div className="splash-container centered">
        <div className="title-container card" style={{ maxWidth: '80%' }}>
          <h1>Welcome to the Modules "Space and Geometry" with Léonie! </h1>
          <p>
            The modules “Space and Geometry”, developed by EvidenceB each propose adaptative courses for a <strong>differentiated teaching</strong>. They allow the teacher to support each student while they acquire the <strong>geometrical concepts</strong> expected in the second cycle by using an essential skill: <strong>space apprehension. </strong>
          </p>
          <p>Here you can:</p>
          <ul>
            <li>-	Test the first module and its adaptative course;</li>
            <li>-	Discover the pedagogical axis of the 7 modules;</li>
            <li>-	Have an overview of the software organization ant the dashboard for the teacher.</li>
          </ul>
        </div>
        <div className="users-container">
          <Link to={`/${ROUTES.EAN13}/${ROUTES.HOME}`} key='student' onClick={() => dispatchUser({ type: 'DEMO_AUTH', payload: [ROLES.STUDENT] })} >
            <div className="user-item card">
              <h3>Test<br />of the modules</h3>
              <img src={StudentImage} width="150px" />
            </div>
          </Link>
          <Link to={`/${ROUTES.EAN13}/${ROUTES.HOME_RESSOURCES}`} key='teacher' onClick={() => dispatchUser({ type: 'DEMO_AUTH', payload: [ROLES.TEACHER] })}>
            <div className="user-item card">
              <h3> Table<br />of contents</h3>
              <img src={TeacherImage} width="150px" />
            </div>
          </Link>
          <Link to={`/${ROUTES.EAN13}/${ROUTES.COMING_SOON}`} key='modules' onClick={() => dispatchUser({ type: 'DEMO_AUTH', payload: [ROLES.TEACHER] })}>
            <div className="user-item card">
              <h3>Module<br />features</h3>
              <img src={fonctionalitesImg} width="150px" />
            </div>
          </Link>
        </div>
      </div>)
  } else if (authUser.roles && authUser.roles.includes(ROLES.STUDENT)) {
    return <Redirect to={`/${ROUTES.EAN13}/${ROUTES.HOME}`} />
  } else if (authUser.roles && authUser.roles.includes(ROLES.TEACHER)) {
    return <Redirect to={`/${ROUTES.EAN13}/${ROUTES.HOME_RESSOURCES}`} />
  }
};

export default Splash;