import React, { useContext, useRef } from 'react';
import { Link, NavLink, useLocation } from 'react-router-dom';

import { StoreContext } from '../Store';
import * as ROLES from '../constants/roles';
import * as ROUTES from '../constants/routes';
import useModules from '../hooks/content/useModules'

import logoBordas from '../assets/images/logo_bordas.png';
import evibLogo from '../assets/images/evidenceb_logo.png';

import "../scss/Navigation.scss";

export const Logo = () => {

  return (
    <div id="logos">
      <div id="bordas">
        <img src={logoBordas} alt='logoBordas' />
      </div>
      <div id="evib">
        <img src={evibLogo} alt='evibLogo' />
        <span>Specimen book</span>
      </div>

    </div>
  );
};

export const MainNav = () => {

  const {
    user: {
      user: authUser,
      dispatchUser
    }
  } = useContext(StoreContext);


  const modules = useModules([]);
  const location = useLocation();

  // console.log('location.pathname', location.pathname);

  const menuRef = useRef(null)

  const openNav = () => {
    menuRef.current.className = "menu-container open"
  }

  const closeNav = () => {
    menuRef.current.className = "menu-container"
  }

  const studentDispatch = () =>{
    menuRef.current.className = "menu-container"
    dispatchUser({ type: 'DEMO_AUTH', payload: [ROLES.STUDENT] })
  }

  const teacherDispatch = () =>{
    menuRef.current.className = "menu-container"
    dispatchUser({ type: 'DEMO_AUTH', payload: [ROLES.TEACHER] })
  }

  return (authUser.id) ? (
    <nav id="main-nav">

      <div className="mobile-menu-open" onClick={openNav}>
        <span className="material-icons">dehaze</span>
      </div>

      <div className="menu-container" ref={menuRef}>

        <div className="mobile-menu-close" onClick={closeNav}>
          <span class="material-icons">close</span>
        </div>

        <ul className="primary">
          <li className="nav-item">
            <NavLink to={`/${ROUTES.EAN13}/${ROUTES.LOGOUT}`}>Home</NavLink>
          </li>
          <li className="nav-item" id="student">
            <NavLink activeClassName="selected" to={`/${ROUTES.EAN13}/${ROUTES.HOME}`} key='student' onClick={studentDispatch} >Test of the modules</NavLink>
            {/* {location.pathname !== `/${ROUTES.EAN13}/${ROUTES.HOME}` ? <ul className="secondary">
              {modules.map((mod, index) => {
                if (index < 2) {
                  return (
                    <li key={mod._id}>
                      <NavLink activeClassName="selected" to={`/${ROUTES.EAN13}/module-${mod.module_id}`} key={mod._id} onClick={studentDispatch} > Module {mod.module_id} </NavLink>
                    </li>
                  )
                }
                return
              })}
            </ul> : null} */}
          </li>
          <li className="nav-item" id="ressource-table">
            <NavLink activeClassName="selected" to={`/${ROUTES.EAN13}/${ROUTES.HOME_RESSOURCES}`} key='ressource-table' onClick={teacherDispatch}>Table of contents</NavLink>
            {/* {location.pathname !== `/${ROUTES.EAN13}/${ROUTES.HOME}` ? <ul className="secondary">
              {modules.map((mod, index) => {
                if (index < 2) {
                  return (
                    <li key={mod._id}>
                      <NavLink activeClassName="selected" to={`/${ROUTES.EAN13}/module-${mod.module_id}-content`} key={mod._id} onClick={teacherDispatch} > Module {mod.module_id} </NavLink>
                    </li>
                  )
                }
                return
              })}
            </ul> : null} */}
          </li>
          <li className="nav-item">
            <NavLink activeClassName="selected" to={`/${ROUTES.EAN13}/${ROUTES.COMING_SOON}`} key="cooming-soon" onClick={closeNav}>Module features</NavLink>
          </li>
        </ul>

      </div>

    </nav>
  ) : null;
};
