import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

export default (init, param) => {

    const params = useParams();
    const [contentTypeId, setContentTypeId] = useState(init);

    useEffect(() => {
      
      if (params && params[param]) {
        setContentTypeId(parseInt(params[param].split('-')[1]));
      }
      
    }, [params[param]])

    return [contentTypeId, setContentTypeId]

}
