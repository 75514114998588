import React, { useState, useEffect } from 'react'
import '../../scss/PointAndClick.scss'
import useModule from '../../hooks/content/useModule';

function PointAndClick({ bg, data, submit }) {
    
    const svg = data && data.svg;
    const background_image = bg;

    const [displayAnswer, setDisplayAnswer] = useState(null)

    const modul = useModule({})

    const onClickHandler = (isCorrect) =>{
        if(!displayAnswer){
            submit(isCorrect, null)
            setDisplayAnswer(true)
        }
    }


    useEffect(()=>{
        if(displayAnswer){
            setDisplayAnswer(false)
        }
        return () => {
            setDisplayAnswer(false)
        }
    },[svg])

    return (
        <div className="pnc-container">
            <div className="svg">
                <svg viewBox="0 0 169.33333 127" className={displayAnswer ? "show" : null}>
                        <g>
                            <path id="errorZone" onClick={ () => onClickHandler(false) } className="error-zone" d="M 1.1303711e-7,2.6806641e-7 V 127 H 169.33333 V 2.6806641e-7 Z" fillOpacity="0" />
                            <path id="clickArea" onClick={ () => onClickHandler(true) }  d={svg} />
                        </g>
                </svg>
                <img src={background_image && "/module" + modul.module_id + "/images/" + background_image + ".jpg"} />
            </div>
        </div>
    )
}

export default PointAndClick
