import React from 'react';
import '../scss/Footer.scss'
import evibLogo from '../assets/images/evidenceb_logo.jpg';

export default () => (
  <div id="footer">
    <div className="evib-logo">
        <img src={evibLogo} />
    </div>
  </div>
);