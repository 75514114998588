import { useEffect, useState,useContext } from 'react';

import useId from '../useId';

import { StoreContext } from '../../Store';

export default (init) => {

  const {
    questions: {
      questions
    }
  } = useContext(StoreContext);

  const [question, setQuestion] = useState(init);
  const [questionId] = useId(1, 'questionParam');

  useEffect(() => {
    if (questions && questions.length) {
      // console.log("questionS", questions)
      setQuestion(questions[questionId-1]);
    } 
  }, [questionId, questions]);
  return question;
}