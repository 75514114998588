import React,{ useEffect, useState, useContext } from 'react';
import useModule from '../hooks/content/useModule';
import useExercice from '../hooks/content/useExercice';
import useQuestion from '../hooks/content/useQuestion';
import { StoreContext } from '../Store';

import '../scss/InstructionMedia.scss';

function InstructionMedia() {

    const {
        user: {
            user
        },
        history: {
            answers
        }
    } = useContext(StoreContext);

    const modul = useModule({});
    const image = useExercice({});
    const question = useQuestion({});
    const [img, setImg] = useState();

    useEffect(() => {    
        if (user.roles[0] === "TEACHER") {
            setImg(image.media);
        } else if (user.roles[0] === "STUDENT") {
            setImg(question.image_instruction_media);
        }
    }, [answers, image, question])

    return ( 
        <div id="instruction-img" className="card content">
            <img src={ img && "/module" + modul.module_id + "/images/" + img + ".jpg"} />
        </div>
    )
}

export default InstructionMedia