import React from 'react';
import { Icon } from 'antd';

import personnalisationImg from '../assets/images/OE-Leonie-schema_adaptif-en.jpg';
import dashboardImg from '../assets/images/OE-Leonie-dashboard-en.jpg';

import "../scss/ComingSoon.scss";

export default () => (
    <div id="coming-soon">

        <div className="card content" >
            <div className="items">
                <div className="item">
                    <Icon type='rise' /> The Space and geometry modules developped by EvidenceB propose <strong>adaptative courses for a differenciated pedagogy</strong>. They assist the teacher to help each student.
                </div>
                <div className="item">
                    <Icon type='rise' /> Conceived by Roberto Casati, CNRS reasercher specialized in orientation question (Jean Nicod Institute) and Bruno Nibas (principal of the Châteaudun elementary school of application  at Amiens), these modules allow the reinforcement of a <strong>fundamental skill, space apprehension</strong>:  by developing this skill, it is easier for students to get the <strong>grasp of the geometrical concepts</strong> of figures and solides.
                    </div>
                <div className="item">
                    <Icon type='rise' /> With these modules, the teacher has the possibility to make his class work in differentiated pedagogy. Following a <strong>diagnostic test</strong>, each student realizes a <strong>personalized course</strong> among the 7 interactive modules containing over 300 exercises: artificial intelligence allows us to select the exercises and difficulty level which are the most likely going to make him progress. A <strong>final verification test</strong> allows the teacher to measure these progresses. 
                    </div>
                <div className="item">
                    <Icon type='rise' /> With his <strong>dashboard</strong>, the teacher has a clear overview of the results of every students of his class. He can easily identify the same level groups proposed by the artificial intelligence algorithm, as well as students having difficulties or those in progress. These information allow him to vary the learning context as well as the remediation methods he wants to propose to his students.</div>
            </div>
        </div>

        <div className="col">
            <div className="card content">
                <h2>Student course personalisation</h2>
                <img src={personnalisationImg} />
            </div>
            <div className="card content">
                <h2>Teacher's dashbord</h2>
                <img src={dashboardImg} />
            </div>
        </div>

    </div>
)
