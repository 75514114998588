// it parse content from json received with GET_ACTIVITIES_CONTENT to get all modules data
import { useContext, useEffect, useState } from 'react';
import { StoreContext } from '../../Store';

export default (init) => {
    const {
        content:{ 
            queryActivitiesContentModule1,
        }
    } = useContext(StoreContext);

    const [modules, setModules] = useState(init);
    useEffect(() => {
        const modules = [...queryActivitiesContentModule1.modules];
        console.log('CONSOLE', modules)
        setModules(modules);
    }, []);

    return modules;
}