const errorHandler = (question) => {
    console.log('ERROR HANDLER', question)
    switch (question.__typename) {
        case "ComponentGameplayQcm":
            let correctAnswer = question.options.find(el => el.isTrue)
            return {
                message: "Oups. The correct answer is",
                isCorrect: false,
                answer: correctAnswer.value
            }
        case "ComponentGameplayPointandclick":
            return {
                message: "Oups. The correct answer is",
                isCorrect: false,
                answer: "in green, on the image."
            }
        case "ComponentGameplayDiorama":
            return {
                message: "Oups. The correct answer is",
                isCorrect: false,
                answer: "the green arrow."
            }
        case "Questions":
            return {
                message: "Oups. that is not correct.",
                isCorrect: false,
                answer: ""
            }
    }
}

const successHandler = (question) => {
    console.log('in reducer ia, question', question)
    switch (question.__typename) {
        case "ComponentGameplayQcm":
            return {
                message: "Well done !",
                isCorrect: true,
                answer: null
            }
        case "ComponentGameplayPointandclick":

            return {
                message: "Well done !",
                isCorrect: true,
                answer: null
            }
        case "ComponentGameplayDiorama":

            return {
                message: "Well done !",
                isCorrect: true,
                answer: null
            }
        case "ComponentGameplayCharactersIntro":
            let msg;
            if (question.isCharacterFind) {
                msg = "Well done !"
            } else {
                msg = "When you are finished, go to the next question by clicking the button underneath."
            }
            return {
                message: msg,
                isCorrect: true,
                answer: null
            }
        case "Questions":
            console.log("State",
                {
                    message: "Well done !",
                    isCorrect: true,
                    answer: null
                });
            return {
                message: "Well done !",
                isCorrect: true,
                answer: null
            }
    }
}

const activityHandler = (question, answersValidity, isLastQuestionCorrect, isLastActivity, activityId) => {

    let activityMessage, msg;
    
    if( 
        activityId === 0 || 
        answersValidity.correct >= 6 || 
        ( ( activityId === 4 || activityId === 5 ) && answersValidity.correct >= 4 )
    ){

        msg = !isLastActivity ? "You've passed this activity, you can go on to the next one !" : "You've passed this activity, and the module is over !"

        if(isLastQuestionCorrect){
            activityMessage = successHandler(question)
        }else{
            activityMessage = errorHandler(question)
        }

        activityMessage.activityEnd = msg;
        activityMessage.btnLabel = !isLastActivity ? "Go to the next activity !" : "Return to the list of the modules."

    }else{

        if(isLastQuestionCorrect){
            activityMessage = successHandler(question)
        }else{
            activityMessage = errorHandler(question)
        }
        
        if(answersValidity.attempts === 0){
            msg = "Improve your score and restart the activity."
            activityMessage.restart = true
            activityMessage.btnLabel = "Restart the activity !"
        }else{
            msg = "Call your teacher for some help."
            activityMessage.restart = false
            activityMessage.btnLabel = !isLastActivity ? "Go to the next activity !" : "Return to the list of the modules."
        }

        activityMessage.activityEnd = msg;
        
    }

    return activityMessage

}

export const feedBackReducer = (state, action) => {

    let payload = action.payload;

    switch (action.type) {
        case 'ERROR_QUESTION':
            return errorHandler(payload.question)
        case 'SUCCESS_QUESTION':
            return successHandler(payload.question)
        case 'END_ACTIVITY': {
            return activityHandler( payload.question, payload.answersValidity, payload.isLastQuestionCorrect, payload.isLastActivity, payload.activityId  )
        }
        case 'RESET_FEEDBACK':
            
            
            return {
                message: null,
                isCorrect: null,
                answer: null
            }
    }
}

export const activityValidation = (state, action) => {

    switch (action.type) {
        case "IS_CORRECT":
            return {
                ...state,
                correct: state.correct + 1
            }
        case "RESET_ANSWERS":
            return {
                correct: 0,
                attempts: 0
            }
        case "RESTART_ACTIVITY":
            return {
                correct: 0,
                attempts: state.attempts + 1
            }
    }
}