import axios from 'axios';

function useAuth() {

    // const getIdUserFromUrl = () => {
    //     let url = window.location.search.split('=')
    //     return url[url.length - 1]
    // }

    // const getAppToken = () => localStorage.getItem('bordas-token')

    const getStapiToken = () => localStorage.getItem('strapi-token')

    // const getUserType = () => {
    //     return localStorage.getItem('hatier-userType')
    // }

    // const serverAuth = async () => {
    //     try {
    //         if (
    //             getIdUserFromUrl() !== ''
    //         ) {
    //             console.log("url", serverUrl + getIdUserFromUrl());
                
    //             const res = await axios.get(serverUrl + getIdUserFromUrl());
    //             console.log('In useAuth res.data', res.data);
    //             if (res && res.data && !res.data.Error) {
    //                 if (res.data.GUIDUser) {
    //                     localStorage.setItem("hatier-token", res.data.GUIDUser)
    //                 }
    //                 if (res.data.userType) {
    //                     localStorage.setItem("hatier-userType", res.data.userType)
    //                     if (res.data.userType === 'teacher') {
    //                         getTeacherStudents()
    //                     }
    //                 }
    //                 return false;
    //             } else {
    //                 if (res && res.data && res.data.Error) {
    //                     console.log(res.data.Error); //to-do specific error when token incorrect
    //                     throw new Error(res.data.Error);
    //                 }
    //                 return true;
    //             }
    //         } else {
    //             if (getUserType() === 'student' || getUserType() === 'teacher' && getAppToken()) { // if previous user was a student, he can stay co
    //                 return false;
    //             } else {
    //                 throw new Error("Erreur d'authentification au serveur");
    //             }
    //         }

    //     } catch (error) {
    //         console.log("Erreur d'authentification au serveur", error);
    //         throw new Error(error);
    //     }
    // }

    // const getTeacherStudents = async () =>{
    //     try {
    //         const res = await axios.get(getDataFromAmbikaUrl + getIdUserFromUrl());
    //         console.log("getTeacherStudents", res.data);
            
    //         if (res && res.data) {
    //             let teacher = {
    //                 isFromGar: res.data.isFromGar,
    //                 nom: res.data.nom,
    //                 prenom: res.data.prenom,
    //                 classes: res.data.classes
    //             }
    //             localStorage.setItem('teacherData', JSON.stringify(teacher));
    //             return false;
    //         } else {
    //             return true;
    //         }
    //     } catch (error) {
    //         console.log("Erreur d'authentification au serveur", error);
    //         throw new Error(error);
    //     }
    // }

    const strapiAuth = async () => {

        if (!getStapiToken() ) {

            let params = new URLSearchParams();
            console.log('in useAuth(), process.env', process.env)
            params.append('identifier', process.env.REACT_APP_STRAPI_USER_EMAIL);
            params.append('password', process.env.REACT_APP_STRAPI_USER_PASSWORD);

            try {
                const res = await axios.post(process.env.REACT_APP_STRAPI_ENDPOINT + '/auth/local', params);
                if (res && res.data) {
                    localStorage.setItem('strapi-token', res.data.jwt);
                    return res.data.jwt;
                }
            } catch (error) {
                console.log("Erreur d'authentification au site de contenu", error);
                throw new Error(error);
            }

        } else {
            return getStapiToken()
        }
    }

    return {
        strapiAuth,
    }
}
export default useAuth;
