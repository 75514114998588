
import gql from 'graphql-tag';

export const GET_COLORABLE_OBJECTS = gql`
  query {
    questions(where: {
      module: {module_id: "2"}
    }){
      key
      choices {
        ... on ComponentGameplayObject3DAnswer {
          gameplay_3_d_object {
            Object3D {
              file_name
            }
          }
        }
      }
    }
  }
`;