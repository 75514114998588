import React, { useState, useEffect } from 'react'

import useModule from '../../hooks/content/useModule';
import Audio from '../../components/Audio'

import '../../scss/Qcm.scss'

function Qcm({ data, submit }) {

    const modul = useModule({})
    const options = data.options;

    // check if multiple possible answers
    const correct_answer = options && options.filter(option => option.isTrue === true)

    const formRef = React.createRef(null)

    const validate = () => {
        submit()
    }

    const onChangeHandler = (e, itemId) => {

        submit(JSON.parse(e.currentTarget.value), [itemId])

        let qcmItems = formRef.current.children

        for (let index = 0; index < qcmItems.length; index++) {
            let label = qcmItems[index].children[0]
            label.children[0].disabled = true
        }
    }

    const qcm =
        <>
            {options && options.map(option =>
                <div className="qcm-item" key={option._id}>
                    <label htmlFor={option._id} className="input-container">
                        {correct_answer.length === 1 ?
                            <input id={option._id} type="radio" name="qcu" value={option.isTrue} onChange={(e) => onChangeHandler(e, option._id)} /> :
                            <input id={option._id} type="checkbox" name="qcm" value={option.isTrue} />
                        }
                        <span className="checkmark"></span>
                        {option.value}
                    </label>
                    {option.voice && option.voice !== "" ? <Audio voice={option.voice} /> : null}
                </div>
            )}
        </>

    return (
        <div className="qcm-container">
            <div className="directive-container">
                <h3>Proposals:</h3>
            </div>
            <form ref={formRef}>
                {qcm}
                {correct_answer && correct_answer.length > 1 ? <button onClick={validate}>Valider</button> : null}
            </form>
        </div>
    )
}

export default Qcm
