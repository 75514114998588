// it parse content from json received with GET_ACTIVITIES_CONTENT to get a specific module data
import { useEffect, useState } from 'react';
import useModules from './useModules';
import useId from '../useId';

export default (init) => {

    const modules = useModules([]);
    const [moduleId, ] = useId(1, 'moduleParam');
    const [modul, setModul] = useState(init);

    useEffect(() => {
        if (modules && modules.length) {
            const mod = modules
                .find(mod => mod.module_id === moduleId);
                setModul(mod);
        }
    }, [modules, moduleId]);

    return modul;
}