const checkIfItemExists = (itemsArray, item_id) => {
    let index = itemsArray.findIndex(item => item._id === item_id)
    if (index >= 0) {
        return index
    }
    return false
}


const addModule = (payload) => {
    return {
        _id: payload.module_id,
        activities: [
            {
                _id: payload.activity_id,
                questions: [
                    {
                        image_id: payload.image_id,
                        _id: payload.question_id,
                        isCorrect: payload.isCorrect,
                        answers_id: payload.answers_id
                    }
                ]
            }
        ]
    }

}

const addActivity = (payload) => {
    return {
        _id: payload.activity_id,
        questions: [
            {
                image_id: payload.image_id,
                _id: payload.question_id,
                isCorrect: payload.isCorrect,
                answers_id: payload.answers_id
            }
        ]
    }
}

const addQuestion = (payload) => {
    return {
        image_id: payload.image_id,
        _id: payload.question_id,
        isCorrect: payload.isCorrect,
        answers_id: payload.answers_id
    }
}

export default (state, action) => {
    console.log('Action', action.type);
    switch (action.type) {
        case 'ADD_ANSWER':

            let modulIndex, activityIndex, questionIndex;
            modulIndex = checkIfItemExists(state, action.payload.module_id)

            // check if module exists
            if (modulIndex === false) {
                state.push(addModule(action.payload))
                return [...state]
            } else {
                // check if activity exists
                activityIndex = checkIfItemExists(state[modulIndex].activities, action.payload.activity_id)
                if (activityIndex === false) {
                    state[modulIndex].activities.push(addActivity(action.payload))
                    return [...state]
                } else {
                    // check if question exists
                    questionIndex = checkIfItemExists(state[modulIndex].activities[activityIndex].questions, action.payload.question_id)
                    if (questionIndex === false) {
                        state[modulIndex].activities[activityIndex].questions.push(addQuestion(action.payload))
                        return [...state]
                        // if True update question data
                    } else {
                        state[modulIndex].activities[activityIndex].questions[questionIndex]._id = action.payload.question_id;
                        state[modulIndex].activities[activityIndex].questions[questionIndex].image_id = action.payload.image_id;
                        state[modulIndex].activities[activityIndex].questions[questionIndex].isCorrect = action.payload.isCorrect;
                        state[modulIndex].activities[activityIndex].questions[questionIndex].answers_id = action.payload.answers_id;
                        return [...state]
                    }
                }
            }
        case 'RESET_ANSWERS':
            return []
    }

}