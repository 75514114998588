import React, { useContext } from 'react'

import { StoreContext } from '../Store';

import useModule from '../hooks/content/useModule';
import useActivity from '../hooks/content/useActivity';
import useActivities from '../hooks/content/useActivities';
import useExercice from '../hooks/content/useExercice';
import useQuestion from '../hooks/content/useQuestion';

import Qcm from '../components/gameplay/Qcm';
import PointAndClick from '../components/gameplay/PointAndClick';
import Diorama from '../components/gameplay/Diorama';
import Characters from '../components/gameplay/Characters';
import CharactersDnD from '../components/gameplay/CharactersDnD';

import * as ROLES from '../constants/roles';

function GameplayContainer() {

  const {
    user: {
      user,
    },
    history: {
      dispatchAnswers,
    },
    gameloop: {
      gameloop: {
        moduleId,
        activityId,
        imageId,
        questionId
      },
      dispatchGameloop
    },
    questions: {
      questions
  },
    ia: {
      dispatchFeedback
    },
    activityValidation: {
      answersValidity,
      dispatchAnswersValidity
    }
  } = useContext(StoreContext);

  const modul = useModule({});
  const activity = useActivity({});
  const activities = useActivities({});
  const image = useExercice({});
  const question = useQuestion({});

  const onSubmit = (isCorrect, coords) => {

    let payload = {
      module_id: modul._id,
      activity_id: activity._id,
      image_id: user.roles[0] === "STUDENT" ? question.image_id : image._id,
      question_id: question._id,
      answers_id: coords,
      isCorrect: isCorrect
    }

    dispatchAnswers({ type: "ADD_ANSWER", payload });

    dispatchGameloop({
      type: "NEXT_QUESTION",
      payload: {
        moduleId,
        activityId,
        imageId,
        questionId,
        role: user.roles[0]
      }
    });

    if (user && user.roles.includes(ROLES.TEACHER)) {
      if (isCorrect) {
        dispatchFeedback({ type: "SUCCESS_QUESTION", payload: { question, answersValidity } });
      } else {
        dispatchFeedback({ type: "ERROR_QUESTION", payload: { question, answersValidity } });
      }
    }

    if (user && user.roles.includes(ROLES.STUDENT)) {
      
      if (isCorrect) {

        dispatchAnswersValidity({ type: "IS_CORRECT" })

        if( questionId < questions.length ){
         
          
          if( 
            answersValidity.correct + 1 === 6 ||
            ( activityId === 4 || activityId === 5 ) && answersValidity.correct + 1 === 4 
            ){
            dispatchFeedback({ type: "END_ACTIVITY", payload: { question, answersValidity: { ...answersValidity, correct: answersValidity.correct + 1 }, isLastQuestionCorrect: true, isLastActivity: activityId === activities.length - 1, activityId: activityId } });
          }else{
            dispatchFeedback({ type: "SUCCESS_QUESTION", payload: { question, answersValidity } });
          }
        }else{
          dispatchFeedback({ type: "END_ACTIVITY", payload: { question, answersValidity: { ...answersValidity, correct: answersValidity.correct + 1 }, isLastQuestionCorrect: true, isLastActivity: activityId === activities.length - 1, activityId: activityId } });
        }

      } else {

        if( questionId < questions.length ){
          dispatchFeedback({ type: "ERROR_QUESTION", payload: { question, answersValidity } });
        }else{
          dispatchFeedback({ type: "END_ACTIVITY", payload: { question, answersValidity, isLastQuestionCorrect: false, isLastActivity: activityId === activities.length - 1 } });
        }

      }
    }

  }

  const shuffleArray = (array) => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  }

  const formatData = (question) => {
    let characters = question.characters;
    let columns = {}
    columns["charactersName"] = {
      name: 'Personnages',
      items: [],
      isColumnOfNames: true,
      isCorrect: false
    }

    characters.forEach(el => {
      columns[el._id] = {}
      columns[el._id].name = el.name;
      columns[el._id].image = el.image;
      columns[el._id].voice = el.voice;
      columns[el._id].items = [];
      columns[el._id].isColumnOfNames = false;
      columns[el._id].isCorrect = false;
      columns["charactersName"].items.push({ name: el.name, id: el._id, voice:el.voice })
    })
    columns["charactersName"].items = shuffleArray([...columns["charactersName"].items])
    return columns

  }

  if (user.roles[0] === "TEACHER") {
    return (
      <div id="gameplay-container" className="card">
        {question && question.__typename === "ComponentGameplayQcm" ? <Qcm key={question._id} data={question} submit={onSubmit} /> : null}
        {question && question.__typename === "ComponentGameplayPointandclick" ? <PointAndClick key={question._id} bg={image.media} data={question} submit={onSubmit} /> : null}
        {question && question.__typename === "ComponentGameplayDiorama" ? <Diorama key={question._id} data={question} submit={onSubmit} /> : null}

        {question && question.__typename === "ComponentGameplayCharactersIntro" && !question.isCharacterFind ? <Characters key={question._id} data={question} submit={onSubmit} /> : null}
        {question && question.__typename === "ComponentGameplayCharactersIntro" && question.isCharacterFind ? <CharactersDnD key={question._id} data={formatData(question)} submit={onSubmit} /> : null}
      </div>
    )
  } else if (user.roles[0] === "STUDENT") {
    return (
      <div id="gameplay-container" className="card">
        {question && question.__typename === "ComponentGameplayQcm" ? <Qcm key={question._id} data={question} submit={onSubmit} /> : null}
        {question && question.__typename === "ComponentGameplayPointandclick" ? <PointAndClick key={question._id} bg={question.image_instruction_media} data={question} submit={onSubmit} /> : null}
        {question && question.__typename === "ComponentGameplayDiorama" ? <Diorama key={question._id} data={question} submit={onSubmit} /> : null}

        {question && question.__typename === "ComponentGameplayCharactersIntro" && !question.isCharacterFind ? <Characters key={question._id} data={question} submit={onSubmit} /> : null}
        {question && question.__typename === "ComponentGameplayCharactersIntro" && question.isCharacterFind ? <CharactersDnD key={question._id} data={formatData(question)} submit={onSubmit} /> : null}
      </div>
    )
  } else {
    return null
  }

}

export default GameplayContainer;

