export default (state, action) => {
  console.log("reducer ERROR");
  console.log("Action", action.type);
  const error = action.payload;

  switch (action.type) {
    // case 'AUTH':
    //   return {
    //     ...state,
    //     auth: error,
    //   }
    case 'STRAPI': 
      return {
        ...state,
        strapi: !Object.values(error).every(item => !item)
      }
    // case 'HISTORY': 
    //   return {
    //     ...state,
    //     history: error
    //   } 
    // case 'TRACKING': 
    //   return {
    //     ...state,
    //     tracking: error
    //   }    
    // case '404':
    //   return {
    //     ...state,
    //     e404: error
    //   }
    default:
      return state;
  }
}