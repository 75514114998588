import { useContext } from 'react';

import useModule from './useModule';
import useExercices from './useExercices';
import useExercice from './useExercice';

import { StoreContext } from '../../Store';
import * as ROLES from '../../constants/roles';
import useId from '../useId';
export default () => {

  const {
    user: {
      user
    },
    questions: {
      setQuestions
    }
  } = useContext(StoreContext);

  const modul = useModule({});
  const images = useExercices({});
  const image = useExercice({});

  const [moduleId] = useId(1, 'moduleParam');
  const [imageId] = useId(1, 'exerciceParam');

  const shuffleArray = (array) => {
    for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
}

  const questionsExtraction = (activity) => {
   
    let imagesItems = activity.images;
    let questionsArray = [];
    
    // for each images(chapter) extract each question(exercice) and add image(chapter) data to it
    imagesItems.length >= 1 && imagesItems.forEach( image => {
      image.questions.forEach( question => {
        let questionItem = {
          ...question,
          image_id: images._id,
          image_instruction: image.instruction,
          image_instruction_media: image.media,
          image_instruction_voice: image.voice 
        }
        questionsArray.push( questionItem )
      });
    });

    // if NOT introductive activity (activity 0) -> randomize array
    return activity.activity_id !== 0 && moduleId !== 2 ? shuffleArray( questionsArray.slice(0,10) ) : questionsArray
  }

  const getQuestions = (activity) => {

    if (user.roles.includes(ROLES.TEACHER) && image.questions) {
      setQuestions(image.questions);
    } else if (user.roles.includes(ROLES.STUDENT) && image.questions) {
      setQuestions(questionsExtraction(activity));
    }
  }

  return {
    getQuestions
  }
}