import React, { useState,useEffect, useContext } from 'react'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import useModule from '../../hooks/content/useModule';
import useQuestion from '../../hooks/content/useQuestion';

import Audio from '../Audio'

import '../../scss/CharactersDnD.scss'

function CharactersDnD({data, submit}) { 

    const [columns, setColumns] = useState(data)
    const modul = useModule({});
    const question = useQuestion({});

    const onDragEnd = (result, columns, setColumns) =>{
        if(!result.destination) return;

        const {draggableId, source, destination} = result;

        if(source.droppableId !== destination.droppableId){

            const sourceName = columns[source.droppableId].items.find(el => el.id === draggableId).name
            const destinationName = columns[destination.droppableId].name;
            
            if( sourceName === destinationName){

                const sourceColumn = columns[source.droppableId];
                const destColumn = columns[destination.droppableId];
                const sourceItems = [...sourceColumn.items];
                const destItems = [...destColumn.items];
                const [removed] = sourceItems.splice(source.index, 1);
    
                destItems.splice(destination.index, 0, removed);
    
                setColumns({
                    ...columns,
                    [source.droppableId]:{
                        ...sourceColumn,
                        items:sourceItems
                    },
                    [destination.droppableId]:{
                        ...destColumn,
                        items:destItems,
                        isCorrect:true
                    }
                })

            }
            
        }else{
            const column = columns[source.droppableId];
            const copiedItems = [...column.items]
            const [removed] = copiedItems.splice(source.index, 1)
            copiedItems.splice(destination.index, 0, removed)
            setColumns({
                ...columns,
                [source.droppableId]:{
                    ...column,
                    items: copiedItems
                }
            })
        }

    }

    useEffect(() => {
        if( columns["charactersName"].items.length === 0 ){
            submit(true, null)
        }
    }, [columns])

    const [questionId, setQuestionId] = useState()

    useEffect(()=>{
        setQuestionId(question._id)
        if(questionId && questionId !== question._id){
            setColumns(data)   
        }
    },[question])


    return (
        <div className="drag-container">
            
            <DragDropContext onDragEnd={result => onDragEnd(result, columns, setColumns)}>

                {Object.entries(columns).map(([id, column]) => (
                    <Droppable droppableId={id} key={id} isDropDisabled={false}>
                        {
                            (provided, snapshot) => {
                                return (
                                    <div className={column.isColumnOfNames ? "column list" : "column item"}>
                                        {column.isColumnOfNames ? <h2>{column.name}</h2> : <img src={"/module" + modul.module_id + "/images/" + column.image + ".jpg"} /> }
                                        <div className={snapshot.isDraggingOver ? "droppable-item isDraggingOver" : "droppable-item"}
                                            {...provided.droppableIdProps}
                                            ref={provided.innerRef}
                                        >
                                            {column.items.map((item, index) => (
                                                <Draggable key={item.id} draggableId={item.id} index={index} isDragDisabled={false}>
                                                    {(provided, snapshot) => {
                                                        return (
                                                            <div className={ snapshot.isDragging ? 'draggable-item isDragging' : 'draggable-item' }
                                                                ref = { provided.innerRef }
                                                                { ...provided.draggableProps }
                                                                { ...provided.dragHandleProps }
                                                                style={{
                                                                    ...provided.draggableProps.style
                                                                }}
                                                            >
                                                               <span>{item.name}</span>
                                                                { item.voice && item.voice !== "" ?
                                                                    <Audio voice={item.voice}></Audio>
                                                                : null }
                                                            </div>
                                                        );
                                                    }}
                                                </Draggable>
                                            ))}
                                            {provided.placeholder}
                                        </div>
                                    </div>
                                )
                            }
                        }
                    </Droppable>
                ))}
            </DragDropContext>
            
        </div>
    )
}

export default CharactersDnD
