import gql from 'graphql-tag';

export const GET_ACTIVITIES_CONTENT_MODULE1 = gql`
# Write your query or mutation here
query {
  modules(where: {module_id: 1}) {
    _id
    module_id
    description
    name
    activities(sort:"activity_id:asc") {
        _id
        activity_id
        name
        images: chapters(sort:"chapter_id:asc"){
          _id
          image_id: chapter_id
          instruction
          media:image
        	voice
          questions: exercices{
            ... on ComponentGameplayQcm {
              __typename
              _id
              question_id: exercice_id
              key
              instruction:question
              voice
              options{
                _id
                value
                voice
                isTrue
              }
            }
            ... on ComponentGameplayQcmImages {
              __typename
              _id
              question_id: exercice_id
              key
              instruction:question
              voice
              options{
                image
                isTrue
              }
            }
            ... on ComponentGameplayPointandclick{
              __typename
              _id
              question_id: exercice_id
              key
              instruction
              voice
              svg
            }
            ... on ComponentGameplayDiorama{
              __typename
              _id
              question_id: exercice_id
              key
              instruction
              voice
              image
              carousel{
                _id
                image
                timer
              }
              arrows{
                _id
                svg
                isTrue
              }
            }
            ... on ComponentGameplayCharactersIntro{
              _id
              question_id: exercice_id
              instruction:question
              voice
              isCharacterFind
              characters{
                _id
                name
                voice
                image
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_ACTIVITIES_CONTENT_MODULE2 = gql`
# Write your query or mutation here
query {
  modules(where: { module_id: "2" }) {
    _id
    module_id
    description
    name
    activities(sort:"activity_id:asc") {
      _id
      activity_id
      name
        images:exercices(sort:"exercice_id:asc") {
          image_id:exercice_id
          instruction
          voice
          media:image
          questions {
            __typename
            _id
            question_id
            exercice {
              image_id: exercice_id
              activity {
                activity_id
              }
            }
            instruction
            voice
            answers {
              ... on ComponentGameplayObject3DAnswer {
                gameplay
                order
                gameplay_3_d_object {
                  name
                  Object3D {
                    file_name
                    obj_id
                    coordinates
                  }
                }
              }
            }
            choices {
              ... on ComponentGameplayObject3DAnswer {
                gameplay
                order
                gameplay_3_d_object {
                  name
                  Object3D {
                    file_name
                    obj_id
                    coordinates
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
