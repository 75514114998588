import React, { createContext, useEffect, useReducer, useState } from 'react';
import { useQuery } from '@apollo/react-hooks';

import userReducer from './reducers/user';
import loadingReducer from './reducers/loading';
import errorReducer from './reducers/error';
import historyReducer from './reducers/history';
import gameloopReducer from './reducers/gameloop';
import { feedBackReducer, activityValidation } from './reducers/ia';

import * as Sentry from '@sentry/browser';

import {
  GET_ACTIVITIES_CONTENT_MODULE1,
  GET_ACTIVITIES_CONTENT_MODULE2
} from './fragments/activities';

import {
  GET_WIP_MODULES
} from './fragments/modules';

import {
  GET_COLORABLE_OBJECTS
} from './fragments/interactiveObjects';

// import * as ROUTES from './constants/routes';
// import * as ROLES from './constants/roles';

export const StoreContext = createContext(null);

export const StoreProvider = ({ children }) => {

  useEffect(() => {
    console.log('STORE IS MOUNTED');
    return () => console.log('STORE UNMOUNTED');
  }, []);

  // const initUser = localStorage.getItem('evidenceB-user') ?
  //   JSON.parse(localStorage.getItem('evidenceB-user')) : null;
  // const [user, storeUser] = useLocalStorage(initUser, 'user');
  const [user, dispatchUser] = useReducer(userReducer, { roles: [] });


  // Connect store to apis
  const [error, dispatchError] = useReducer(errorReducer, null);
  const [loading, dispatchLoading] = useReducer(loadingReducer, { strapi: false });

  const getWIPModules = useQuery(GET_WIP_MODULES);
  const getActivitiesContentModule1 = useQuery(GET_ACTIVITIES_CONTENT_MODULE1);
  const getActivitiesContentModule2 = useQuery(GET_ACTIVITIES_CONTENT_MODULE2);
  const getColorableObjects = useQuery(GET_COLORABLE_OBJECTS);

  const queryWIPModules = getWIPModules.data;
  const queryActivitiesContentModule1 = getActivitiesContentModule1.data;
  const queryActivitiesContentModule2 = getActivitiesContentModule2.data;
  const queryColorableObjects = getColorableObjects.data;

  useEffect(() => {
    dispatchLoading({
      type: 'STRAPI',
      payload: {
        getWIPModules: getWIPModules.loading,
        getActivitiesContentModule1: getActivitiesContentModule1.loading,
        getActivitiesContentModule2: getActivitiesContentModule2.loading,
        getColorableObjects: getColorableObjects.loading
      }
    });
  },
  [
    getWIPModules.loading,
    getActivitiesContentModule1.loading,
    getActivitiesContentModule2.loading,
    getColorableObjects.loading
  ]);

  useEffect(() => {
    const errorMap = {
      getWIPModules: getWIPModules.error,
      getActivitiesContentModule1: getActivitiesContentModule1.error,
      getActivitiesContentModule2: getActivitiesContentModule2.error,
      getColorableObjects: getColorableObjects.error
    };

    Sentry.captureException(errorMap);

    dispatchError({
      type: 'STRAPI',
      payload: errorMap
    });
  },
  [
    getWIPModules.error,
    getActivitiesContentModule1.error,
    getActivitiesContentModule2.error,
    getColorableObjects.error
  ]);

  const [answers, dispatchAnswers] = useReducer(historyReducer, []);
  const [gameloop, dispatchGameloop] = useReducer(gameloopReducer, {moduleId: 1, activityId: 1, imageId: 1, questionId: 1});
  
  const [feedback, dispatchFeedback] = useReducer(feedBackReducer, '');
/*   const [rules, dispatchRules] = useReducer(rulesReducer, { questionsPerActivity: 0 }); */
  
  const [questions, setQuestions] = useState([]);
  const [answersValidity, dispatchAnswersValidity] = useReducer(activityValidation, {correct:0, attempts:0});
  const [showModal, setShowModal] = useState(false);
  const [showChatbot, setShowChatbot] = useState(null);
  
  const store = {
    user: {
      user,
      dispatchUser,
    },
    error: {
      error,
      dispatchError
    },
    loading: {
      loading,
      dispatchLoading
    },
    content: {
      queryWIPModules,
      queryActivitiesContentModule1,
      queryActivitiesContentModule2,
      queryColorableObjects
    },
    questions: {
      questions,
      setQuestions
    },
    activityValidation:{
      answersValidity,
      dispatchAnswersValidity
    },
    history: {
      answers,
      dispatchAnswers,
    },
    gameloop: {
      gameloop,
      dispatchGameloop,
    },
    ia: {
      feedback,
      dispatchFeedback,
/*       rules,
      dispatchRules */
    },
    modal: {
      showModal,
      setShowModal
    },
    chatbot: {
      showChatbot,
      setShowChatbot
    },
  };

  return (
    <StoreContext.Provider value={store}>
      {children}
    </StoreContext.Provider>
  );
};

export default StoreProvider;