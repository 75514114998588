import React from 'react';

import useQuestion from '../hooks/content/useQuestion';
import Audio from "../components/Audio"

import '../scss/Chatbot.scss';

export const Chatbot = () => {

  const question = useQuestion({});

  return (
    <div id='chatbot' className="card">
      <div className="directive-container">
        <h3>Question :</h3>
        <p>{ question && question.instruction }</p>
      </div>
      { question && question.voice && question.voice !== "" ? <Audio voice={question.voice} /> : null } 
    </div>
  )
}
