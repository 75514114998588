import React, { useEffect, useContext } from 'react'
import useModule from '../../hooks/content/useModule';
import useQuestion from '../../hooks/content/useQuestion';

import { StoreContext } from '../../Store';

import Audio from '../Audio'

import '../../scss/Characters.scss'

function Characters({data, submit}) {

    const {
        ia: {
            dispatchFeedback
        },
        activityValidation: {
            answersValidity,
            dispatchAnswersValidity
        }
    } = useContext(StoreContext);

    const { characters } = data;

    const modul = useModule({});
    const question = useQuestion({});

    useEffect(() => {
        if(question.__typename){
            dispatchFeedback({ type: "SUCCESS_QUESTION", payload: {question: question, answersValidity: answersValidity} });
        }
        return () =>{
            dispatchFeedback({ type: "RESET_FEEDBACK" });
        }
    }, [question])

    const items =
        <div className="characters-container">
            {
                characters && characters.length ? characters.map((character) => (
                    <>
                        { character.voice && character.voice !== "" ? <Audio voice={character.voice} >
                            <div key={character._id} className="character-item">
                                <img src={"/module" + modul.module_id + "/images/" + character.image + ".jpg"} />
                                <div className="character-name">{character.name}</div>
                            </div>
                        </Audio> : null }
                    </>
                )) : null
            }
        </div>

    return (
        <div>
            {items}
        </div>
    )
}

export default Characters

