import React, { useState } from 'react';

export default ({ objectName, fileName, onClick }) => {
    // const [selected, toggleSelected] = useState('');

    return (
        // <div className={`inventory-item ${selected}`} onClick={() => {
        <div className='inventory-item' onClick={() => {
          // selected ? toggleSelected('') : toggleSelected('selected');
          onClick({ objectName, fileName })
        }}>
          <img style={{width: '30px'}} src={`${process.env.PUBLIC_URL}/module2/${fileName}.png`} alt=""/>
          <div>{objectName}</div>
        </div>
    )
}
