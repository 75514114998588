import React, { useContext, useEffect, useState } from 'react';

import useModule from '../hooks/content/useModule';
import useActivity from '../hooks/content/useActivity';
import useQuestion from '../hooks/content/useQuestion';
import useId from '../hooks/useId';
import { StoreContext } from '../Store';

import * as ROLES from '../constants/roles';

import '../scss/ProgressBar.scss'

export default () => {

    const {
        history: {
            answers
        },
        questions:{
            questions
        }
    } = useContext(StoreContext);

    const [questionId,] = useId(1, 'questionParam');

    const modul = useModule({});
    const activity = useActivity({});

    const question = useQuestion({});

    const [currentStep, setCurrentStep] = useState(questionId);

    const [questionItems, setQuestionItems] = useState([]);

    const getIsCorrect = () => {
       
        let m, a, q;

            m = answers.find(mod => mod._id === modul._id);
            if (m) {
                a = m.activities.find(act => act._id === activity._id);
                q = a.questions.find(qst => qst._id === question._id);
                return q && q.isCorrect 
            }
        
    }

    useEffect(() => {

        if(questionItems.length > 0 ){
            let i = questionItems.findIndex(el => el._id === question._id);
            let updatedQuestions = [...questionItems];
            updatedQuestions[i].isCorrect = getIsCorrect();
            setQuestionItems(updatedQuestions);
        } 

    }, [answers])

    useEffect(() => {
        setCurrentStep(questionId - 1)
    }, [questionId])
    
    useEffect(() => {
        let arrayCopy = JSON.parse(JSON.stringify(questions)); 
        setQuestionItems(arrayCopy) 
    }, [questions])

    const defaultClass = "progress-item"
    const selected = defaultClass + " selected"
    const isCorrect = defaultClass + " correct"
    const isFalse = defaultClass + " error"

    return (
        <div id="progress-bar">

            { questionItems.map( (item, index) => {
                let currentClass = currentStep === index ? 
                    selected :
                    currentStep !== index && item.isCorrect ?
                        isCorrect :
                        currentStep !== index && item.isCorrect === false ?
                            isFalse :
                            defaultClass;
                return <div className={currentClass} key={item._id}>{index + 1}</div>
            })}
        </div>
    )
}

