import { useEffect, useState } from 'react';

import useActivity from './useActivity';

export default (init) => {

  const activity = useActivity({});
  const [images, setImages] = useState(init);

  useEffect(() => {
    if (activity.images && activity.images.length) {
      setImages(activity.images);
    }
    
  }, [activity]);

  return images;
}