// it parse content from json received with GET_ACTIVITIES_CONTENT to get activities data from a specific module
import { useEffect, useState } from 'react';
import useModule from './useModule';

export default (init) => {

    const modul = useModule({});
    const [activities, setActivities] = useState(init);

    useEffect(() => {
        if (modul && JSON.stringify(modul) !== '{}' ) {
            const activities = modul
                .activities
            setActivities(activities);
        }
    }, [modul]);

    return activities;
}
