import React, { useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';

import { StoreContext } from '../Store';
import * as ROUTES from '../constants/routes';
import * as ROLES from '../constants/roles';
import useModules from '../hooks/content/useModules';
import '../scss/Home.scss';

export default () => {

  const {
    user: {
      user
    }
  } = useContext(StoreContext);

  const modules = useModules([]);

  return (
    <div className="home-container centered">
      <div className="title-container">
        {/* <h1> Liste des modules </h1> */}
        <br />
        <br />
      </div>
      <div className="items-container">
        {user.roles && user.roles.includes(ROLES.TEACHER) && <div className="card content">
          <p>
            The modules « Space and gemometry » allow the students to acquire the skills of the program in Space and Geometry : <strong>« Locate oneself and move around using landmarks and representation ».</strong><br />
            Each of the 7 modules is organised in 3 to 6 activities proposing a <strong>great variety of images and questions.</strong> The <strong>difficulty</strong> of theses ressources is <strong>growing</strong> : the software <strong>adapts</strong> the course of each students to his comprenhension level.
          </p>
        </div>}
        {modules.map(mod => (
          <div key={mod._id} className="card">
            {mod.activities ?
              <Link to={() => `/${ROUTES.EAN13}/module-${mod.module_id}${user.roles && user.roles.includes(ROLES.TEACHER) ? "-content" : ""}`}>
                <div className="item">
                  <h2>Module {mod.module_id} : {mod.name} </h2>
                  <p> {mod.description} </p>
                </div>
              </Link> : <div className="item grey">
                <h2>Module {mod.module_id} : {mod.name} </h2>
                <p> {mod.description} </p>
              </div>}
          </div>
        ))}
      </div>
    </div>
  )
}