import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';

import { StoreContext } from '../Store';

import useActivities from '../hooks/content/useActivities';

import * as ROUTES from '../constants/routes';
import * as ROLES from '../constants/roles';

import '../scss/Feedback.scss'

const Feedback = () => {

    const {
        user: {
            user,
        },
        ia: {
            feedback,
            dispatchFeedback,
        },
        gameloop: {
            gameloop,
            dispatchGameloop
        },
        questions: {
            questions,
            setQuestions
        },
        activityValidation: {
            answersValidity,
            dispatchAnswersValidity
        },
    } = useContext(StoreContext);

    const {
        moduleId,
        activityId,
        imageId,
        questionId
    } = gameloop;

    const history = useHistory();

    const activities = useActivities([]);

    const onClickHandler = () => {

        let payload = {
            moduleId,
            activityId,
            imageId,
            questionId,
            history,
            role: user.roles[0]
        }

        if (user && user.roles.includes(ROLES.TEACHER)) {

            if (questionId < questions.length) {
                dispatchGameloop({
                    type: "NEXT_QUESTION",
                    payload: payload
                });
            } else {
                setQuestions([])
                history.push(`/${ROUTES.EAN13}/module-${moduleId}-ressource`);
            }
        }

        if (user && user.roles.includes(ROLES.STUDENT)) {

            if ( questionId < questions.length ) {

                if (activityId === 0 || moduleId === 2) {
                    dispatchGameloop({
                        type: "NEXT_QUESTION",
                        payload: payload
                    });
                }

                if (  moduleId !== 2 && ( activityId === 4 || activityId === 5 ) ) {

                    if ( answersValidity.correct === 4 ) {
                       
                        dispatchAnswersValidity({ type: "RESET_ANSWERS" })
                        if( activityId !== activities.length - 1 ){
                            dispatchGameloop({
                                type: "NEXT_ACTIVITY",
                                payload: payload
                            });
                        }else{
                            history.push(`/${ROUTES.EAN13}/${ROUTES.HOME}`);
                        }

                    } else {
                       
                        dispatchGameloop({
                            type: "NEXT_QUESTION",
                            payload: payload
                        });
                    }
                }else if( moduleId !== 2 && activityId !== 0 ){

                    if ( answersValidity.correct === 6 ) {
                        dispatchAnswersValidity({ type: "RESET_ANSWERS" })
                        if( activityId !== activities.length - 1 ){
                            dispatchGameloop({
                                type: "NEXT_ACTIVITY",
                                payload: payload
                            });
                        }else{
                            history.push(`/${ROUTES.EAN13}/${ROUTES.HOME}`);
                        }
                    } else {
                        dispatchGameloop({
                            type: "NEXT_QUESTION",
                            payload: payload
                        });
                    }

                }

            } else {

                setQuestions([])
               
                if ( activityId <= activities.length - 1 ) {
                  
                    if ( moduleId === 2 || activityId === 0 ) {
                        if ( moduleId === 2){
                            if( activities[activityId+1] ){
                                dispatchAnswersValidity({ type: "RESET_ANSWERS" })
                                dispatchGameloop({
                                    type: "NEXT_ACTIVITY",
                                    payload: payload
                                });
                            }else{
                                history.push(`/${ROUTES.EAN13}/${ROUTES.HOME}`);
                            }
                        }else{
                            dispatchAnswersValidity({ type: "RESET_ANSWERS" })
                            dispatchGameloop({
                                type: "NEXT_ACTIVITY",
                                payload: payload
                            });
                        }
                    }
                    
                    if ( moduleId !== 2 && ( activityId === 4 || activityId === 5 ) ) {
                       
                        if ( answersValidity.correct <= 4 && answersValidity.attempts === 0 ) {
                           
                            dispatchAnswersValidity({ type: "RESTART_ACTIVITY" })
                            dispatchGameloop({
                                type: "RESET_ACTIVITY",
                                payload: payload
                            });

                        } else {
                            
                            dispatchAnswersValidity({ type: "RESET_ANSWERS" })

                            if( activityId !== activities.length - 1 ){
                                dispatchGameloop({
                                    type: "NEXT_ACTIVITY",
                                    payload: payload
                                });
                            }else{
                                history.push(`/${ROUTES.EAN13}/${ROUTES.HOME}`);
                            }
                        }
                        
                    }else if( moduleId !== 2 && activityId !== 0 ){
                       
                        if ( answersValidity.correct <= 6 && answersValidity.attempts === 0 ) {
                           
                            dispatchAnswersValidity({ type: "RESTART_ACTIVITY" })
                            dispatchGameloop({
                                type: "RESET_ACTIVITY",
                                payload: payload
                            });
                        } else {
                           
                            dispatchAnswersValidity({ type: "RESET_ANSWERS" })

                            if(activityId !== activities.length - 1){
                                dispatchGameloop({
                                    type: "NEXT_ACTIVITY",
                                    payload: payload
                                });
                            }else{
                                history.push(`/${ROUTES.EAN13}/${ROUTES.HOME}`);
                            }

                        }

                    }


                } else {

                    history.push(`/${ROUTES.EAN13}/${ROUTES.HOME}`);

                }
            }

        }
        dispatchFeedback({ type: "RESET_FEEDBACK" })
    }

    return (
        <div id="feedback-container" className={feedback && feedback.message ? "show card directive-container" : "directive-container card"}>
            {/* <div id="feedback-container" className="card show"> */}
            <h3>Answer :</h3>
            <div id="message">
                <span id="feedback"> {feedback && feedback.isCorrect ? <i class="material-icons">mood</i> : <i class="material-icons">sentiment_dissatisfied</i>}
                    <br /> {feedback && feedback.message} </span>
                {feedback && feedback.answer ? <span id="answer"> {feedback.answer} </span> : null}
            </div>
            {feedback && feedback.activityEnd ?
                <div id="activity-message">
                    <i class="material-icons">sentiment_satisfied</i><br />{feedback.activityEnd}
                </div> : null
            }
            <button className="btn" onClick={onClickHandler}>
                {feedback && feedback.btnLabel ? feedback.btnLabel : "Next question"}
            </button>
        </div>
    )
}

export default Feedback