// it parse content from json received with GET_ACTIVITIES_CONTENT to get current activity data
import { useEffect, useState } from 'react';
import useActivities from './useActivities';
import useId from '../useId';

export default (init) => {

    const [activityId] = useId(1, 'activityParam');
    const activities = useActivities([]);
    const [activity, setActivity] = useState(init);

    useEffect(() => {
        if (activities && activities.length) {
            
            const act = activities.find( a => a.activity_id === activityId );

            setActivity(act);
        }

        

    }, [activities, activityId]);

    return activity;
}