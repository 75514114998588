import { useHistory } from 'react-router-dom';
import * as ROUTES from '../constants/routes';

const Logout = () => {
  const history = useHistory();
  localStorage.clear();
  console.log("SHOULD LOG OUT");
  window.location.replace(`${process.env.PUBLIC_URL}/${ROUTES.EAN13}/${ROUTES.SPLASH}`);
  return null;
}

export default Logout;